import { Clear } from "@mui/icons-material";
import {
  DialogContent,
  Button,
  Grid,
  TextField,
  Box,
  styled,
} from "@mui/material";
import DialogComponent from "./DialogComponent";
import { MessageDto, TargetEntity, TargetService } from "../../InfohubService";
import { StyledButton } from "../button/StyledButton";
import { StyledTextField } from "../textfield/StyledTextfield";
import { useEffect } from "react";

const ClearIconWrapper = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderRadius: "50%",
  backgroundColor: "#FF5B5B",
  cursor: "pointer",
  marginRight: 5,
  marginTop: 4,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
}));

interface TargetsDialogProps {
  openTargets: boolean;
  handleCloseTargets: () => void;
  setUnsavedSelectedMessageTargets: React.Dispatch<
    React.SetStateAction<TargetEntity[]>
  >;
  unsavedSelectedMessageTargets: TargetEntity[];
  selectedMessageTargets: TargetEntity[];
  setResultMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenResults: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMessage: MessageDto | null;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isDialogLoading: boolean;
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

const TargetsDialog: React.FC<TargetsDialogProps> = ({
  openTargets,
  handleCloseTargets,
  setUnsavedSelectedMessageTargets,
  unsavedSelectedMessageTargets,
  setResultMessage,
  setOpenResults,
  selectedMessageTargets,
  selectedMessage,
  setIsDialogLoading,
  isDialogLoading,
  setWarningMessage,
  setOpenWarning,
}) => {
  useEffect(() => {
    if (openTargets) {
      const existingTargets = selectedMessageTargets?.filter(
        (target) =>
          target?.name !== "special-event" &&
          target?.name !== "programType" &&
          target?.name !== "pid"
      );
      if (existingTargets?.length === 0) {
        setUnsavedSelectedMessageTargets([{} as TargetEntity]);
      }
    }
  }, [openTargets]);

  const handleTargetEdit = (
    value: string,
    index: number,
    property: "name" | "value"
  ) => {
    setUnsavedSelectedMessageTargets((prevTargets) => {
      const updatedTargets = [...prevTargets];
      const target = { ...updatedTargets[index] };
      target[property] = value;
      updatedTargets[index] = target;
      return updatedTargets;
    });
  };

  const handleDeleteTargetItemClick = (index: number) => {
    setUnsavedSelectedMessageTargets((prevFilterDtos) =>
      prevFilterDtos.filter((_, i) => i !== index)
    );
  };

  const checkIfTargetsHasChanged = () =>
    unsavedSelectedMessageTargets.length !== selectedMessageTargets.length ||
    unsavedSelectedMessageTargets.some((unsavedTarget, index) => {
      const selectedTarget = selectedMessageTargets[index];
      return (
        (unsavedTarget.partitionKey !== selectedTarget?.partitionKey ||
          unsavedTarget.rowKey !== selectedTarget?.rowKey ||
          unsavedTarget.timestamp !== selectedTarget?.timestamp ||
          unsavedTarget.eTag !== selectedTarget?.eTag ||
          unsavedTarget.messageId !== selectedTarget?.messageId ||
          unsavedTarget.name !== selectedTarget?.name ||
          unsavedTarget.value !== selectedTarget?.value) &&
        unsavedTarget.name &&
        unsavedTarget.value &&
        unsavedTarget.name?.trim() !== "" &&
        unsavedTarget.value?.trim() !== ""
      );
    });

  const handleAddTarget = async () => {
    setUnsavedSelectedMessageTargets((prevTargets) => {
      const updatedTargets = [...prevTargets];
      const newTarget = {
        messageId: selectedMessage?.rowKey,
        name: "",
        value: "",
      } as TargetEntity;
      updatedTargets.push(newTarget);
      return updatedTargets;
    });
  };

  const handleSaveChangesTargets = async () => {
    const hasChanged = checkIfTargetsHasChanged();
    if (hasChanged) {
      setOpenResults(true);
      setIsDialogLoading(true);
      var withoutEmptyItems = unsavedSelectedMessageTargets.filter(
        (dto) =>
          dto.name &&
          dto.value &&
          dto.name?.trim() !== "" &&
          dto.value?.trim() !== ""
      );
      await TargetService.postTargetsUpdate(withoutEmptyItems)
        .then(() => {
          handleCloseTargets();
          setResultMessage("A targetek szerkesztése sikeres volt.");
          setIsDialogLoading(false);
        })
        .catch((e) => {
          setWarningMessage("A targetek szerkesztése nem sikerült.");
          setOpenResults(false);
          setOpenWarning(true);
          setIsDialogLoading(false);
        });
    } else {
      setOpenWarning(true);
      setWarningMessage("Nincsenek menthető változtatások.");
    }
  };
  return (
    <DialogComponent
      isDialogLoading={isDialogLoading}
      setIsDialogLoading={setIsDialogLoading}
      openMessageDialog={openTargets}
      onClose={handleCloseTargets}
      title={"Szűrőfeltételek szerkesztése"}
      handleClose={handleCloseTargets}
      handleSave={handleSaveChangesTargets}
    >
      <DialogContent style={{ paddingTop: 0 }}>
        <StyledButton size="small" onClick={handleAddTarget}>
          Új hozzáadása
        </StyledButton>
        {unsavedSelectedMessageTargets?.map((target, index) => (
          <Grid
            container
            spacing={2}
            alignItems="center"
            key={index}
            display={
              target?.name !== "special-event" &&
              target?.name !== "programType" &&
              target?.name !== "pid"
                ? "-moz-initial"
                : "none"
            }
          >
            <Grid item xs={5}>
              <StyledTextField
                label="Név"
                name="name"
                value={target?.name ?? ""}
                onChange={(event) =>
                  handleTargetEdit(event.target.value, index, "name")
                }
                fullWidth
                margin="dense"
                size="small"
              />
            </Grid>
            <Grid item xs={0.5} fontWeight={"bold"}>
              :
            </Grid>
            <Grid item xs={5}>
              <StyledTextField
                margin="dense"
                label="Érték"
                name="value"
                value={target?.value ?? ""}
                onChange={(event) =>
                  handleTargetEdit(event.target.value, index, "value")
                }
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item>
              <ClearIconWrapper
                onClick={() => handleDeleteTargetItemClick(index)}
              >
                <Clear sx={{ color: "white" }} />
              </ClearIconWrapper>
            </Grid>
          </Grid>
        ))}
      </DialogContent>
    </DialogComponent>
  );
};

export default TargetsDialog;
