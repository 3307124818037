import { Tooltip } from "@mui/material";
import { handleOpenFilterDialog } from "../../utils/FilterActions";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { FilterDto } from "../../InfohubService";
import "./CircularMenu.css";
import { productIds } from "../../utils/auth/Constants";

interface Props {
  filterDtos: FilterDto[];
  openFilterDialog: boolean;
  setUnsavedFilterDtos: React.Dispatch<React.SetStateAction<FilterDto[]>>;
  setOpenFilterDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setProgramTypeFilters: React.Dispatch<React.SetStateAction<string[]>>;
  setProductIdFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

const FilterMessagesMenuItem: React.FC<Props> = ({
  filterDtos,
  openFilterDialog,
  setUnsavedFilterDtos,
  setOpenFilterDialog,
  setProgramTypeFilters,
  setProductIdFilters,
}) => {
  return (
    <li>
      <div className="placeholder">
        <div className="upside">
          <a href="#" className="button">
            <Tooltip title={"Szűrés"}>
              <FilterAltIcon
                style={{
                  height: "45px",
                  width: "45px",
                  marginBottom: "-5px",
                  marginRight: "-10px",
                }}
                className={
                  filterDtos?.length > 0
                    ? "filter-icon"
                    : openFilterDialog
                    ? "active-icon"
                    : ""
                }
                onClick={() =>
                  handleOpenFilterDialog(
                    setUnsavedFilterDtos,
                    filterDtos,
                    setOpenFilterDialog,
                    setProgramTypeFilters,
                    setProductIdFilters
                  )
                }
              />
            </Tooltip>
          </a>
        </div>
      </div>
    </li>
  );
};

export default FilterMessagesMenuItem;
