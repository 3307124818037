import {
  MessageDto,
  SpecialEventDto,
  SpecialEventService,
  TargetEntity,
  TargetService,
} from "../InfohubService";

export const handleCloseConnectSpecialEventToMessageDialog = (
  setOpenConnectSpecialEventToMessageDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setSelectedSpecialEventId: React.Dispatch<
    React.SetStateAction<string | null>
  >,
  setSpecEvents: React.Dispatch<React.SetStateAction<SpecialEventDto[] | null>>
) => {
  setOpenConnectSpecialEventToMessageDialog(false);
  setSelectedSpecialEventId(null);
  setSpecEvents(null);
};

export const openEditSpecialEvent = (
  event: SpecialEventDto,
  setOpenEditSpecialEventDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedSpecialEventDto: React.Dispatch<
    React.SetStateAction<SpecialEventDto | null>
  >
) => {
  setOpenEditSpecialEventDialog(true);
  setSelectedSpecialEventDto(event);
};

export const handleSpecialEventConnectClick = async (
  message: MessageDto,
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>,
  setOpenConnectSpecialEventToMessageDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  specEvents: SpecialEventDto[] | null,
  setSpecEvents: React.Dispatch<React.SetStateAction<SpecialEventDto[] | null>>,
  setSelectedSpecialEventId: React.Dispatch<
    React.SetStateAction<string | null>
  >,
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>,
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (message) {
    setOpenConnectSpecialEventToMessageDialog(true);
    setIsDialogLoading(true);
    setSelectedMessage(message);

    await SpecialEventService.getSpecialevent1()
      .then((res) => {
        setSpecEvents(res);
      })
      .catch((e) => console.log(e));

    await TargetService.getTargets(message.partitionKey ?? "")
      .then((res) => {
        if (res && res[0]) {
          var specialeventtarget = res.find(
            (i: TargetEntity) => i.name === "special-event"
          );
          if (specialeventtarget) {
            setSelectedSpecialEventId(
              (specialeventtarget as TargetEntity).value ?? ""
            );
          } else setSelectedSpecialEventId("-1");
        }
        setIsDialogLoading(false);
      })
      .catch((e) => {
        setOpenConnectSpecialEventToMessageDialog(false);
        setWarningMessage("Nem sikerült a speciális események betöltése.");
        setOpenWarning(true);
        setIsDialogLoading(false);
      });
  } else {
    setWarningMessage("Nem sikerült a speciális események betöltése.");
    setOpenWarning(true);
  }
};

export const handleCloseEditSpecialEventDialog = (
  setOpenEditSpecialEventDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedSpecialEventDto: React.Dispatch<
    React.SetStateAction<SpecialEventDto | null>
  >
) => {
  setOpenEditSpecialEventDialog(false);
  setSelectedSpecialEventDto(null);
};

export const handleCloseConfirmDeleteSpecialEventDialog = (
  setOpenConfirmDeleteSpecialEventDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setSelectedSpecialEventId: React.Dispatch<React.SetStateAction<string | null>>
) => {
  setOpenConfirmDeleteSpecialEventDialog(false);
  setSelectedSpecialEventId(null);
};

export const handleSpecialEventsClick = async (
  setSpecEvents: React.Dispatch<React.SetStateAction<SpecialEventDto[] | null>>,
  setOpenSpecialEventsDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>,
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setOpenSpecialEventsDialog(true);
  setIsDialogLoading(true);
  await SpecialEventService.getSpecialevent1()
    .then((res) => {
      setSpecEvents(res);
      setIsDialogLoading(false);
    })
    .catch((e) => {
      setOpenSpecialEventsDialog(false);
      setOpenWarning(true);
      setWarningMessage("Nem sikerült a speciális események betöltése.");
      setIsDialogLoading(false);
    });
};
