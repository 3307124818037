import { Clear } from "@mui/icons-material";
import {
  DialogContent,
  Button,
  Grid,
  TextField,
  Box,
  styled,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  css,
  IconButton,
} from "@mui/material";
import DialogComponent from "./DialogComponent";
import { FilterDto } from "../../InfohubService";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { RefreshContext } from "../../utils/GlobalContexts";
import { productIds, programTypes } from "../../utils/auth/Constants";
import { handleMessageEdit } from "../../utils/MessageActions";
import { convertProgramNamesToIds } from "../../utils/HelperFunctions";
import { StyledButton } from "../button/StyledButton";
import { StyledTextField } from "../textfield/StyledTextfield";
import { StyledSelect } from "../select/StyledSelect";
import ClearIcon from "@mui/icons-material/Clear";
import SelectClearButton from "../button/SelectClearButton";

const labelStyles = css`
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 1;
  color: #c4c4c4;

  &.Mui-focused {
    margin: 0;
    font-size: 15px;
    color: rgb(0, 119, 255, 0.2);
  };
  &:hover fieldset: {
    borderColor: #cae2fc,
  };
}
`;

const formControlStyles = css`
  width: 100%;
  margintop: 5px;

  & label.Mui-focused {
    color: rgb(0, 119, 255, 0.2); /* Set the desired label color when focused */
  }

  & .MuiInputBase-root {
    height: 30px;
    font-size: 14px;
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: rgb(
        0,
        119,
        255,
        0.2
      ); /* Set the desired border color when focused */
      &:hover fieldset: {
        bordercolor: #cae2fc;
      }
    }
  }
`;

export const StyledFormControl = styled(FormControl)`
  ${formControlStyles}
`;

export const StyledInputLabel = styled(InputLabel)`
  ${labelStyles}
`;

const ClearIconWrapper = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderRadius: "50%",
  backgroundColor: "#FF5B5B",
  cursor: "pointer",
  marginRight: 5,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
}));

interface FilterDialogProps {
  openFilterDialog: boolean;
  handleCloseFilterDialog: () => void;
  unsavedFilterDtos: FilterDto[];
  setUnsavedFilterDtos: React.Dispatch<React.SetStateAction<FilterDto[]>>;
  setFilterDtos: React.Dispatch<React.SetStateAction<FilterDto[]>>;
  setProgramTypeFilters: React.Dispatch<React.SetStateAction<string[]>>;
  programTypeFilters: string[];
  setProductIdFilters: React.Dispatch<React.SetStateAction<string[]>>;
  productIdFilters: string[];
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isDialogLoading: boolean;
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>;
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
}

const FilterDialog: React.FC<FilterDialogProps> = ({
  openFilterDialog,
  handleCloseFilterDialog,
  unsavedFilterDtos,
  setUnsavedFilterDtos,
  setFilterDtos,
  programTypeFilters,
  setProgramTypeFilters,
  setProductIdFilters,
  productIdFilters,
  setIsDialogLoading,
  isDialogLoading,
  setOpenWarning,
  setWarningMessage,
}) => {
  const { setRefresh } = useContext(RefreshContext);

  useEffect(() => {
    if (openFilterDialog) {
      const existingTargets = unsavedFilterDtos?.filter(
        (dto) =>
          dto?.name !== "special-event" &&
          dto?.name !== "programType" &&
          dto?.name !== "pid"
      );
      if (existingTargets?.length === 0) {
        setUnsavedFilterDtos([{} as FilterDto]);
      }
    }
  }, [openFilterDialog]);

  const handleAddFilterDto = async () => {
    setUnsavedFilterDtos((prevTargets) => {
      const updatedTargets = [...prevTargets];
      const newTarget = {} as FilterDto;
      updatedTargets.push(newTarget);
      return updatedTargets;
    });
  };

  const filterMessages = async () => {
    const updatedFilterDtos = [
      ...unsavedFilterDtos.filter(
        (dto) =>
          dto.name?.trim() !== "" &&
          dto.value?.trim() !== "" &&
          dto.name &&
          dto.value
      ),
      ...convertProgramNamesToIds(programTypeFilters, programTypes).map(
        (filter) => ({
          name: "programType",
          value: filter,
        })
      ),
      ...convertProgramNamesToIds(productIdFilters, productIds).map(
        (filter) => ({
          name: "pid",
          value: filter,
        })
      ),
    ];
    if (updatedFilterDtos?.length > 0) {
      setFilterDtos(updatedFilterDtos);
      handleCloseFilterDialog();
      setRefresh(true);
    } else {
      setOpenWarning(true);
      setWarningMessage("Nem adott meg szűrőfeltételeket.");
    }
  };

  const handleFilterDtoChange = (
    value: string,
    index: number,
    property: "name" | "value"
  ) => {
    setUnsavedFilterDtos((prevTargets) => {
      const updatedTargets = [...prevTargets];
      const target = { ...updatedTargets[index] };
      target[property] = value;
      updatedTargets[index] = target;
      return updatedTargets;
    });
  };

  const handleDeleteFilterDtoItemClick = (index: number) => {
    setUnsavedFilterDtos((prevFilterDtos) =>
      prevFilterDtos.filter((_, i) => i !== index)
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const inputWidth = () =>
    document?.getElementById("title-id")?.offsetWidth ?? 552;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function handleClearClick(
    setValue: React.Dispatch<SetStateAction<any>>,
    value: any
  ): void {
    setValue(value);
  }

  return (
    <DialogComponent
      isDialogLoading={isDialogLoading}
      setIsDialogLoading={setIsDialogLoading}
      title={"Szűrés"}
      handleClose={handleCloseFilterDialog}
      handleSave={filterMessages}
      saveButtonText="Szűrés"
      openMessageDialog={openFilterDialog}
      onClose={handleCloseFilterDialog}
    >
      <DialogContent style={{ paddingTop: 0 }}>
        <StyledFormControl
          sx={{ width: `${inputWidth() ?? 200}px` }}
          margin="dense"
        >
          <StyledInputLabel
            size="small"
            sx={{
              fontSize: 14,
              "&.Mui-focused": {
                color: "rgb(0,119,255, 0.4)", // Set the desired label color when focused
              },
            }}
          >
            Programtípusok
          </StyledInputLabel>
          <StyledSelect
            multiple
            size="small"
            name="programType"
            label="Programtípusok"
            value={programTypeFilters}
            onChange={(event: any) =>
              setProgramTypeFilters(
                Array.isArray(event.target.value)
                  ? event.target.value
                  : [event.target.value]
              )
            }
            renderValue={(selected: any) => selected.join(", ")}
            MenuProps={MenuProps}
            endAdornment={
              <SelectClearButton
                condition={programTypeFilters?.length > 0}
                clearAction={() => handleClearClick(setProgramTypeFilters, [])}
              />
            }
          >
            {programTypes?.map((programType) => {
              const isChecked =
                unsavedFilterDtos?.some(
                  (filterDto) =>
                    filterDto.name === "programType" &&
                    filterDto.value === programType.productName
                ) ||
                programTypeFilters.some((x) => x === programType.productName);

              return (
                <MenuItem
                  dense
                  disableGutters
                  sx={{ p: 0 }}
                  key={programType.productName}
                  value={programType.productName}
                >
                  <Checkbox checked={isChecked || false} />
                  <ListItemText primary={programType.productName} />
                </MenuItem>
              );
            })}
          </StyledSelect>
        </StyledFormControl>

        <StyledFormControl
          sx={{ width: `${inputWidth() ?? 200}px` }}
          margin="dense"
        >
          <StyledInputLabel
            size="small"
            sx={{
              fontSize: 14,
              "&.Mui-focused": {
                color: "rgb(0,119,255, 0.4)", // Set the desired label color when focused
              },
            }}
          >
            Termékkódok
          </StyledInputLabel>
          <StyledSelect
            multiple
            size="small"
            name="pid"
            label="Termékkódok"
            value={productIdFilters}
            onChange={(event) =>
              setProductIdFilters(
                Array.isArray(event.target.value)
                  ? event.target.value
                  : [event.target.value]
              )
            }
            renderValue={(selected: any) => selected.join(", ")}
            MenuProps={MenuProps}
            endAdornment={
              <SelectClearButton
                condition={productIdFilters?.length > 0}
                clearAction={() => handleClearClick(setProductIdFilters, [])}
              />
            }
          >
            {productIds?.map((productId) => {
              const isChecked =
                unsavedFilterDtos?.some(
                  (filterDto) =>
                    filterDto.name === "pid" &&
                    filterDto.value === productId.productName
                ) || productIdFilters.some((x) => x === productId.productName);

              return (
                <MenuItem
                  dense
                  disableGutters
                  sx={{ p: 0 }}
                  key={productId.productName}
                  value={productId.productName}
                >
                  <Checkbox checked={isChecked || false} />
                  <ListItemText primary={productId.productName} />
                </MenuItem>
              );
            })}
          </StyledSelect>
        </StyledFormControl>

        <StyledButton size="small" onClick={handleAddFilterDto}>
          Szűrőfeltétel hozzáadása
        </StyledButton>
        {unsavedFilterDtos?.map((filterDto, index) => (
          <Grid container spacing={2} alignItems="center" key={index}>
            <Grid item xs={5}>
              <StyledTextField
                label="Név"
                name="name"
                value={filterDto.name ?? ""}
                onChange={(event) =>
                  handleFilterDtoChange(event.target.value, index, "name")
                }
                fullWidth
                margin="dense"
                size="small"
              />
            </Grid>
            <Grid item xs={0.5} fontWeight={"bold"}>
              :
            </Grid>
            <Grid item xs={5}>
              <StyledTextField
                label="Érték"
                name="value"
                value={filterDto?.value ?? ""}
                onChange={(event) =>
                  handleFilterDtoChange(event.target.value, index, "value")
                }
                fullWidth
                margin="dense"
                size="small"
              />
            </Grid>
            <Grid item>
              <ClearIconWrapper
                onClick={() => handleDeleteFilterDtoItemClick(index)}
              >
                <Clear sx={{ color: "white" }} />
              </ClearIconWrapper>
            </Grid>
          </Grid>
        ))}
      </DialogContent>
    </DialogComponent>
  );
};

export default FilterDialog;
