import React, { SetStateAction, useState } from "react";
import {
  FilterDto,
  MessageDto,
  SpecialEventDto,
  TemplateDto,
} from "../../InfohubService";
import "./CircularMenu.css";
import ToggleActiveMessagesMenuItem from "./ToggleActiveMessagesMenuItem";
import PlaceholderMenuItem from "./PlaceholderMenuItem";
import FilterMessagesMenuItem from "./FilterMessagesMenuItem";
import SearchMessagesMenuItem from "./SearchMessagesMenuItem";
import DeleteFiltersMenuItem from "./DeleteFiltersMenuItem";
import AddMessageMenuItem from "./AddMessageMenuItem";
import SpecialEventsMenuItem from "./SpecialEventsMenuItem";
import AddTemplateMenuItem from "./AddTemplateMenuItem";

interface Props {
  filterDtos: FilterDto[];
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>;
  setUnsavedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>;
  setOpenMessageDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenSpecialEventsDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSpecEvents: React.Dispatch<React.SetStateAction<SpecialEventDto[] | null>>;
  setFilterDtos: React.Dispatch<React.SetStateAction<FilterDto[]>>;
  setUnsavedFilterDtos: React.Dispatch<React.SetStateAction<FilterDto[]>>;
  setOpenFilterDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setProgramTypeFilters: React.Dispatch<React.SetStateAction<string[]>>;
  setProductIdFilters: React.Dispatch<React.SetStateAction<string[]>>;
  activeMessagesClicked: boolean;
  openFilterDialog: boolean;
  openMessageDialog: boolean;
  openSpecialEventsDialog: boolean;
  setActiveMessagesClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>;
  setTemplates: React.Dispatch<React.SetStateAction<TemplateDto[]>>;
  setOpenTemplateDialog: React.Dispatch<SetStateAction<boolean>>;
}

const CircularMenu: React.FC<Props> = ({
  filterDtos,
  setIsDialogLoading,
  setSelectedMessage,
  setOpenMessageDialog,
  setOpenSpecialEventsDialog,
  setSpecEvents,
  setFilterDtos,
  setUnsavedFilterDtos,
  setOpenFilterDialog,
  setProgramTypeFilters,
  setActiveMessagesClicked,
  activeMessagesClicked,
  openFilterDialog,
  openSpecialEventsDialog,
  setProductIdFilters,
  openMessageDialog,
  setWarningMessage,
  setOpenWarning,
  setTemplates,
  setUnsavedMessage,
  setOpenTemplateDialog,
}) => {
  const [newMessageDialogOpen, setNewMessageDialogOpen] = useState(false);
  return (
    <section className="menu menu--circle">
      <input type="checkbox" id="menu__active" />
      <label htmlFor="menu__active" className="menu__active">
        <div className="menu__toggle">
          <div className="icon">
            <div className="hamburger"></div>
          </div>
        </div>
        <input type="radio" name="arrow--up" id="degree--up-0" />
        <input type="radio" name="arrow--up" id="degree--up-2" />
        <div className="menu__listings">
          <ul className="circle">
            <FilterMessagesMenuItem
              filterDtos={filterDtos}
              setOpenFilterDialog={setOpenFilterDialog}
              setProgramTypeFilters={setProgramTypeFilters}
              setUnsavedFilterDtos={setUnsavedFilterDtos}
              openFilterDialog={openFilterDialog}
              setProductIdFilters={setProductIdFilters}
            />
            {filterDtos?.length > 0 ? (
              <DeleteFiltersMenuItem setFilterDtos={setFilterDtos} />
            ) : (
              <ToggleActiveMessagesMenuItem
                activeMessagesClicked={activeMessagesClicked}
                setActiveMessagesClicked={setActiveMessagesClicked}
              />
            )}
            <PlaceholderMenuItem />
            <AddMessageMenuItem
              setUnsavedMessage={setUnsavedMessage}
              openMessageDialog={openMessageDialog}
              newMessageDialogOpen={newMessageDialogOpen}
              setNewMessageDialogOpen={setNewMessageDialogOpen}
              setOpenMessageDialog={setOpenMessageDialog}
              setSelectedMessage={setSelectedMessage}
              setIsDialogLoading={setIsDialogLoading}
              setTemplates={setTemplates}
              setWarningMessage={setWarningMessage}
              setOpenWarning={setOpenWarning}
            />
            <AddTemplateMenuItem
              setOpenTemplateDialog={setOpenTemplateDialog}
            />
            {filterDtos?.length > 0 ? (
              <ToggleActiveMessagesMenuItem
                activeMessagesClicked={activeMessagesClicked}
                setActiveMessagesClicked={setActiveMessagesClicked}
              />
            ) : (
              <PlaceholderMenuItem />
            )}
            <PlaceholderMenuItem />
            <PlaceholderMenuItem />
            <PlaceholderMenuItem />
            <SpecialEventsMenuItem
              setIsDialogLoading={setIsDialogLoading}
              setOpenSpecialEventsDialog={setOpenSpecialEventsDialog}
              setSpecEvents={setSpecEvents}
              openSpecialEventsDialog={openSpecialEventsDialog}
              setWarningMessage={setWarningMessage}
              setOpenWarning={setOpenWarning}
            />
          </ul>
        </div>
        <div className="menu__arrow menu__arrow--top">
          <ul>
            <li>
              <label htmlFor="degree--up-0">
                <div className="arrow-container">
                  <div className="arrow">
                    <div className="line"></div>
                  </div>
                </div>
              </label>
              <label htmlFor="degree--up-2">
                <div className="arrow-container">
                  <div
                    className="arrow"
                    style={{ transform: "rotate(135deg)" }}
                  >
                    <div className="line"></div>
                  </div>
                </div>
              </label>
            </li>
          </ul>
        </div>
      </label>
    </section>
  );
};

export default CircularMenu;
