import { DialogContent } from "@mui/material";
import DialogComponent from "./DialogComponent";
import {
  SpecialEventDto,
  SpecialEventService,
  TargetService,
} from "../../InfohubService";

interface ConfirmDeleteSpecialEventDialogProps {
  openConfirmDeleteSpecialEventDialog: boolean;
  handleCloseConfirmDeleteSpecialEventDialog: () => void;
  selectedSpecialEventId: string | null;
  specEvents: SpecialEventDto[] | null;
  setSpecEvents: React.Dispatch<React.SetStateAction<SpecialEventDto[] | null>>;
  setResultMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenResults: React.Dispatch<React.SetStateAction<boolean>>;
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isDialogLoading: boolean;
}

const ConfirmDeleteSpecialEventDialog: React.FC<
  ConfirmDeleteSpecialEventDialogProps
> = ({
  openConfirmDeleteSpecialEventDialog,
  handleCloseConfirmDeleteSpecialEventDialog,
  selectedSpecialEventId,
  specEvents,
  setSpecEvents,
  setResultMessage,
  setOpenResults,
  setWarningMessage,
  setOpenWarning,
  setIsDialogLoading,
  isDialogLoading,
}) => {
  const handleDeleteSpecialEvent = () => {
    if (selectedSpecialEventId) {
      setOpenResults(true);
      setIsDialogLoading(true);
      SpecialEventService.deleteSpecialevent(selectedSpecialEventId)
        .then(
          async () =>
            await TargetService.postTargetsSpecialEvent(selectedSpecialEventId)
              .then(() => {
                setSpecEvents(
                  specEvents?.filter(
                    (e) => e.partitionKey !== selectedSpecialEventId
                  ) ?? []
                );
                setResultMessage("A speciális esemény törlése sikeres volt.");
                handleCloseConfirmDeleteSpecialEventDialog();
                setIsDialogLoading(false);
              })
              .catch((e) => {
                setOpenWarning(true);
                setOpenResults(false);
                setWarningMessage(
                  "A speciális esemény törlése nem volt sikeres."
                );
                setIsDialogLoading(false);
              })
        )
        .catch((e) => {
          setOpenWarning(true);
          setOpenResults(false);
          setWarningMessage("A speciális esemény törlése nem volt sikeres.");
          setIsDialogLoading(false);
        });
    }
  };

  return (
    <DialogComponent
      isDialogLoading={isDialogLoading}
      setIsDialogLoading={setIsDialogLoading}
      openMessageDialog={openConfirmDeleteSpecialEventDialog}
      onClose={handleCloseConfirmDeleteSpecialEventDialog}
      title={"Figyelmeztetés"}
      handleClose={handleCloseConfirmDeleteSpecialEventDialog}
      closeButtonText={"Mégse"}
      handleSave={handleDeleteSpecialEvent}
      saveButtonText={"Törlés"}
    >
      <DialogContent style={{ paddingTop: 0 }}>
        {
          "A speciális esemény törlésével a hozzárendelt üzenettel való kapcsolata (ha van ilyen) is megszűnik. Biztosan törölni szeretné?"
        }
      </DialogContent>
    </DialogComponent>
  );
};

export default ConfirmDeleteSpecialEventDialog;
