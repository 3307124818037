import { Box, CircularProgress } from "@mui/material";

export const LoadingScreen = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Set the height of the container to fill the viewport
      }}
    >
      <CircularProgress size={100} /> {/* Increase the size as needed */}
    </Box>
  );
};
