export enum DIALOGS {
  EDITMESSAGE,
  RESULTS,
  FILTERMESSAGES,
  EDITTARGETS,
}

export enum LOCAL_STORAGE_KEY {
  MS_AUTH_TOKEN = "msAuthToken",
}

export enum INTERNAL_ROUTE {
  START = "/",
  LOGIN = "/login",
  LOADING = "/loading",
  MESSAGES = "/messages",
}

export enum ICON_TYPE {
  DELETE,
  TARGET,
  EXTERNALLINK,
  SPECIALEVENT,
  EDIT,
  ADD,
}
