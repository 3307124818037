import { Fragment } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { INTERNAL_ROUTE } from "../GlobalEnums";
import { Button, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StatusPage from "../../pages/status/StatusPage";
import { StyledButton } from "../../components/button/StyledButton";

type Props = {
  children: React.ReactNode;
};

const RequireUser = ({ children }: Props) => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const doesntNeedAuthentication = [
    INTERNAL_ROUTE.START,
    INTERNAL_ROUTE.LOGIN,
    INTERNAL_ROUTE.LOADING,
  ] as string[];

  const handleRedirectButtonClicked = () => navigate(INTERNAL_ROUTE.START);

  return (
    <Fragment>
      {isAuthenticated && children}
      {!isAuthenticated &&
        !doesntNeedAuthentication.includes(window.location.pathname) && (
          <StatusPage
            title={"Hozzáférés megtagadva"}
            description={"Az oldal eléréséhez be kell jelentkezned"}
            withKSLogo={false}
            action={
              <StyledButton onClick={handleRedirectButtonClicked}>
                Új hozzáadása
              </StyledButton>
            }
          />
        )}
    </Fragment>
  );
};

export default RequireUser;
