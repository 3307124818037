import { Box, Button, Table, TableContainer, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  FilterDto,
  MessageDto,
  SpecialEventDto,
  SpecialEventService,
  TargetEntity,
  TargetService,
  TemplateDto,
} from "../../InfohubService";
import TableHeaders from "./TableHeaders";
import TableBodyComponent from "./TableBodyComponent";
import TableActionMenu from "./TableActionMenu";
import TablePagination from "./TablePagination";
import MessageDialog from "../dialog/MessageDialog";
import ConfirmDeleteMessageDialog from "../dialog/ConfirmDeleteMessageDialog";
import ConfirmDeleteSpecialEventDialog from "../dialog/ConfirmDeleteSpecialEventDialog";
import EditSpecialEventDialog from "../dialog/EditSpecialEventDialog";
import FilterDialog from "../dialog/FilterDialog";
import MessageSpecialEventDialog from "../dialog/MessageSpecialEventDialog";
import ResultDialog from "../dialog/ResultDialog";
import SpecialEventsDialog from "../dialog/SpecialEventsDialog";
import TargetsDialog from "../dialog/TargetsDialog";
import {
  handleCloseResults,
  handleCloseWarning,
} from "../../utils/DialogActions";
import { handleCloseConfirmDeleteMessageDialog } from "../../utils/MessageActions";
import {
  handleSpecialEventConnectClick,
  handleCloseEditSpecialEventDialog,
  openEditSpecialEvent,
  handleCloseConfirmDeleteSpecialEventDialog,
  handleCloseConnectSpecialEventToMessageDialog,
} from "../../utils/SpecialEventActions";
import { handleCloseTargets } from "../../utils/TargetActions";
import { handleCloseFilterDialog } from "../../utils/FilterActions";
import CircularMenu from "../circular-menu/CircularMenu";
import WarningDialog from "../dialog/WarningDialog";
import { StyledTableContainer } from "./StyledTableContainer";
import TemplateDialog from "../dialog/TemplateDialog";
import { handleCloseTemplateDialog } from "../../utils/TemplateActions";

interface MessageListViewProps {
  messages: MessageDto[];
  isLoading: boolean;
  handleChangePage: any;
  page: number;
  totalCount: number;
  searchPhrase: string;
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>;
  filterDtos: FilterDto[];
  setFilterDtos: React.Dispatch<React.SetStateAction<FilterDto[]>>;
  templates: TemplateDto[];
  setActiveMessagesClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setTemplates: React.Dispatch<React.SetStateAction<TemplateDto[]>>;
  activeMessagesClicked: boolean;
}

const MessageTableView: React.FC<MessageListViewProps> = ({
  messages,
  isLoading,
  handleChangePage,
  page,
  totalCount,
  searchPhrase,
  setSearchPhrase,
  filterDtos,
  setFilterDtos,
  templates,
  activeMessagesClicked,
  setActiveMessagesClicked,
  setTemplates,
}) => {
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [openResults, setOpenResults] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [openConfirmDeleteMessageDialog, setOpenConfirmDialog] =
    useState(false);
  const [
    openConnectSpecialEventToMessageDialog,
    setOpenConnectSpecialEventToMessageDialog,
  ] = useState(false);
  const [openTargets, setOpenTargets] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [selectedMessage, setSelectedMessage] = useState<MessageDto | null>(
    null
  );
  const [selectedMessageTargets, setSelectedMessageTargets] = useState(
    [] as TargetEntity[]
  );
  const [unsavedSelectedMessageTargets, setUnsavedSelectedMessageTargets] =
    useState([] as TargetEntity[]);
  const [selectedSpecialEventId, setSelectedSpecialEventId] = useState<
    string | null
  >(null);
  const [selectedSpecialEventDto, setSelectedSpecialEventDto] =
    useState<SpecialEventDto | null>(null);

  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [unsavedFilterDtos, setUnsavedFilterDtos] = useState([] as FilterDto[]);
  const [openSpecialEventsDialog, setOpenSpecialEventsDialog] = useState(false);
  const [
    openConfirmDeleteSpecialEventDialog,
    setOpenConfirmDeleteSpecialEventDialog,
  ] = useState(false);

  const [openEditSpecialEventDialog, setOpenEditSpecialEventDialog] =
    useState(false);
  const [specEvents, setSpecEvents] = useState<SpecialEventDto[] | null>(null);

  const [programTypeFilters, setProgramTypeFilters] = useState([] as string[]);
  const [productIdFilters, setProductIdFilters] = useState([] as string[]);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [unsavedMessage, setUnsavedMessage] = useState<MessageDto | null>(null);
  const [unsavedTemplate, setUnsavedTemplate] = useState<string | null>(null);

  return (
    <Box>
      <CircularMenu
        openMessageDialog={openMessageDialog}
        setIsDialogLoading={setIsDialogLoading}
        filterDtos={filterDtos}
        setProductIdFilters={setProductIdFilters}
        setSelectedMessage={setSelectedMessage}
        setOpenMessageDialog={setOpenMessageDialog}
        setOpenSpecialEventsDialog={setOpenSpecialEventsDialog}
        setSpecEvents={setSpecEvents}
        setFilterDtos={setFilterDtos}
        setUnsavedFilterDtos={setUnsavedFilterDtos}
        setOpenFilterDialog={setOpenFilterDialog}
        setProgramTypeFilters={setProgramTypeFilters}
        activeMessagesClicked={activeMessagesClicked}
        setActiveMessagesClicked={setActiveMessagesClicked}
        openFilterDialog={openFilterDialog}
        openSpecialEventsDialog={openSpecialEventsDialog}
        setOpenWarning={setOpenWarning}
        setWarningMessage={setWarningMessage}
        setTemplates={setTemplates}
        setUnsavedMessage={setUnsavedMessage}
        setOpenTemplateDialog={setOpenTemplateDialog}
      />
      <TableActionMenu
        openMessageDialog={openMessageDialog}
        setIsDialogLoading={setIsDialogLoading}
        setSearchPhrase={setSearchPhrase}
        setSelectedMessage={setSelectedMessage}
        setOpenMessageDialog={setOpenMessageDialog}
        setTemplates={setTemplates}
        setOpenWarning={setOpenWarning}
        setWarningMessage={setWarningMessage}
        setUnsavedMessage={setUnsavedMessage}
      />

      <StyledTableContainer>
        <Table size="small" stickyHeader>
          <TableHeaders />
          <TableBodyComponent
            setUnsavedMessage={setUnsavedMessage}
            setTemplates={setTemplates}
            setOpenWarning={setOpenWarning}
            setWarningMessage={setWarningMessage}
            setIsDialogLoading={setIsDialogLoading}
            messages={messages}
            activeFilters={filterDtos?.length > 0 || searchPhrase !== ""}
            isLoading={isLoading}
            setOpenMessageDialog={setOpenMessageDialog}
            setSelectedMessage={setSelectedMessage}
            setOpenTargets={setOpenTargets}
            setResultMessage={setResultMessage}
            setSelectedMessageTargets={setSelectedMessageTargets}
            setSelectedMessageTargetsUpdated={setUnsavedSelectedMessageTargets}
            setOpenResults={setOpenResults}
            setOpenConfirmDialog={setOpenConfirmDialog}
            handleSpecialEventConnectClick={(message: MessageDto) =>
              handleSpecialEventConnectClick(
                message,
                setSelectedMessage,
                setOpenConnectSpecialEventToMessageDialog,
                specEvents,
                setSpecEvents,
                setSelectedSpecialEventId,
                setIsDialogLoading,
                setWarningMessage,
                setOpenWarning
              )
            }
          />
        </Table>
        <TablePagination
          totalCount={totalCount}
          page={page}
          handleChangePage={handleChangePage}
        />
      </StyledTableContainer>

      <MessageDialog
        openMessageDialog={openMessageDialog}
        isDialogLoading={isDialogLoading}
        setIsDialogLoading={setIsDialogLoading}
        setOpenMessageDialog={setOpenMessageDialog}
        selectedMessage={selectedMessage}
        setResultMessage={setResultMessage}
        setOpenResults={setOpenResults}
        setSelectedMessage={setSelectedMessage}
        templates={templates}
        setOpenWarning={setOpenWarning}
        setWarningMessage={setWarningMessage}
        unsavedMessage={unsavedMessage}
        setUnsavedMessage={setUnsavedMessage}
      />

      <TemplateDialog
        openTemplateDialog={openTemplateDialog}
        handleClose={() => handleCloseTemplateDialog(setOpenTemplateDialog)}
        isDialogLoading={isDialogLoading}
        setIsDialogLoading={setIsDialogLoading}
        setOpenResults={setOpenResults}
        setResultMessage={setResultMessage}
        setWarningMessage={setWarningMessage}
        setOpenTemplateDialog={setOpenTemplateDialog}
        setOpenWarning={setOpenWarning}
        unsavedTemplate={unsavedTemplate}
        setUnsavedTemplate={setUnsavedTemplate}
      />

      <ResultDialog
        isDialogLoading={isDialogLoading}
        setIsDialogLoading={setIsDialogLoading}
        openResults={openResults}
        handleCloseResults={() =>
          handleCloseResults(setOpenResults, setResultMessage)
        }
        resultMessage={resultMessage}
      />

      <WarningDialog
        isDialogLoading={isDialogLoading}
        setIsDialogLoading={setIsDialogLoading}
        openWarning={openWarning}
        handleCloseWarning={() =>
          handleCloseWarning(setOpenWarning, setWarningMessage)
        }
        warningMessage={warningMessage}
      />

      <EditSpecialEventDialog
        isDialogLoading={isDialogLoading}
        setIsDialogLoading={setIsDialogLoading}
        openEditSpecialEventDialog={openEditSpecialEventDialog}
        handleCloseEditSpecialEventDialog={() =>
          handleCloseEditSpecialEventDialog(
            setOpenEditSpecialEventDialog,
            setSelectedSpecialEventDto
          )
        }
        selectedSpecialEventDto={selectedSpecialEventDto}
        setSelectedSpecialEventDto={setSelectedSpecialEventDto}
        setSpecEvents={setSpecEvents}
        setResultMessage={setResultMessage}
        setOpenResults={setOpenResults}
        setOpenWarning={setOpenWarning}
        setWarningMessage={setWarningMessage}
      />

      <SpecialEventsDialog
        isDialogLoading={isDialogLoading}
        setIsDialogLoading={setIsDialogLoading}
        openSpecialEventsDialog={openSpecialEventsDialog}
        openEditSpecialEvent={(event) =>
          openEditSpecialEvent(
            event,
            setOpenEditSpecialEventDialog,
            setSelectedSpecialEventDto
          )
        }
        specEvents={specEvents}
        setOpenConfirmDeleteSpecialEventDialog={
          setOpenConfirmDeleteSpecialEventDialog
        }
        setSelectedSpecialEventId={setSelectedSpecialEventId}
        setSpecEvents={setSpecEvents}
        setOpenSpecialEventsDialog={setOpenSpecialEventsDialog}
      />

      <ConfirmDeleteMessageDialog
        isDialogLoading={isDialogLoading}
        setIsDialogLoading={setIsDialogLoading}
        openConfirmDeleteMessageDialog={openConfirmDeleteMessageDialog}
        handleCloseConfirmDeleteMessageDialog={() =>
          handleCloseConfirmDeleteMessageDialog(setOpenConfirmDialog)
        }
        setResultMessage={setResultMessage}
        setOpenResults={setOpenResults}
        selectedMessage={selectedMessage}
        setOpenWarning={setOpenWarning}
        setWarningMessage={setWarningMessage}
      />

      <ConfirmDeleteSpecialEventDialog
        isDialogLoading={isDialogLoading}
        setIsDialogLoading={setIsDialogLoading}
        openConfirmDeleteSpecialEventDialog={
          openConfirmDeleteSpecialEventDialog
        }
        handleCloseConfirmDeleteSpecialEventDialog={() =>
          handleCloseConfirmDeleteSpecialEventDialog(
            setOpenConfirmDeleteSpecialEventDialog,
            setSelectedSpecialEventId
          )
        }
        selectedSpecialEventId={selectedSpecialEventId}
        specEvents={specEvents}
        setSpecEvents={setSpecEvents}
        setResultMessage={setResultMessage}
        setOpenResults={setOpenResults}
        setOpenWarning={setOpenWarning}
        setWarningMessage={setWarningMessage}
      />

      <MessageSpecialEventDialog
        setOpenWarning={setOpenWarning}
        setWarningMessage={setWarningMessage}
        isDialogLoading={isDialogLoading}
        setIsDialogLoading={setIsDialogLoading}
        openConnectSpecialEventToMessageDialog={
          openConnectSpecialEventToMessageDialog
        }
        handleCloseConnectSpecialEventToMessageDialog={() =>
          handleCloseConnectSpecialEventToMessageDialog(
            setOpenConnectSpecialEventToMessageDialog,
            setSelectedSpecialEventId,
            setSpecEvents
          )
        }
        specEvents={specEvents}
        selectedSpecialEventId={selectedSpecialEventId}
        setSelectedSpecialEventId={setSelectedSpecialEventId}
        setResultMessage={setResultMessage}
        setOpenResults={setOpenResults}
        selectedMessage={selectedMessage}
      />

      <TargetsDialog
        isDialogLoading={isDialogLoading}
        setIsDialogLoading={setIsDialogLoading}
        openTargets={openTargets}
        handleCloseTargets={() =>
          handleCloseTargets(
            setSelectedMessage,
            setSelectedMessageTargets,
            setUnsavedSelectedMessageTargets,
            setOpenTargets
          )
        }
        unsavedSelectedMessageTargets={unsavedSelectedMessageTargets}
        selectedMessageTargets={selectedMessageTargets}
        setResultMessage={setResultMessage}
        setOpenResults={setOpenResults}
        selectedMessage={selectedMessage}
        setUnsavedSelectedMessageTargets={setUnsavedSelectedMessageTargets}
        setOpenWarning={setOpenWarning}
        setWarningMessage={setWarningMessage}
      />

      <FilterDialog
        isDialogLoading={isDialogLoading}
        setIsDialogLoading={setIsDialogLoading}
        openFilterDialog={openFilterDialog}
        handleCloseFilterDialog={() =>
          handleCloseFilterDialog(
            setOpenFilterDialog,
            setUnsavedFilterDtos,
            setProgramTypeFilters,
            setProductIdFilters
          )
        }
        unsavedFilterDtos={unsavedFilterDtos}
        setUnsavedFilterDtos={setUnsavedFilterDtos}
        setFilterDtos={setFilterDtos}
        programTypeFilters={programTypeFilters}
        setProgramTypeFilters={setProgramTypeFilters}
        productIdFilters={productIdFilters}
        setProductIdFilters={setProductIdFilters}
        setOpenWarning={setOpenWarning}
        setWarningMessage={setWarningMessage}
      />
    </Box>
  );
};

export default MessageTableView;
