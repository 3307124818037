export type ProgramType = {
  productName: string;
  id: string;
};

export type ProductId = {
  productName: string;
  id: string;
};

export const programTypes: ProgramType[] = [
  {
    productName: "Ügyvitel",
    id: "1",
  },
  {
    productName: "Főkönyv",
    id: "3",
  },
  {
    productName: "Bér",
    id: "2",
  },
];

export const productIds: ProductId[] = [
  {
    productName: "Főkönyv - Könyvelés",
    id: "2",
  },
  {
    productName: "Főkönyv - Tárgyi",
    id: "27",
  },
  {
    productName: "Főkönyv - Egyszeres",
    id: "26",
  },
  {
    productName: "Főkönyv - Autó",
    id: "6",
  },
];
