import { Tooltip } from "@mui/material";
import { handleOpenNewMessageDialog } from "../../utils/MessageActions";
import "./CircularMenu.css";
import { SetStateAction, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { MessageDto, TemplateDto } from "../../InfohubService";

interface Props {
  openMessageDialog: boolean;
  setOpenMessageDialog: React.Dispatch<SetStateAction<boolean>>;
  setNewMessageDialogOpen: React.Dispatch<SetStateAction<boolean>>;
  newMessageDialogOpen: boolean;
  setSelectedMessage: React.Dispatch<SetStateAction<MessageDto | null>>;
  setUnsavedMessage: React.Dispatch<SetStateAction<MessageDto | null>>;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTemplates: React.Dispatch<React.SetStateAction<TemplateDto[]>>;
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddMessageMenuItem: React.FC<Props> = ({
  setOpenMessageDialog,
  openMessageDialog,
  newMessageDialogOpen,
  setNewMessageDialogOpen,
  setSelectedMessage,
  setIsDialogLoading,
  setTemplates,
  setWarningMessage,
  setOpenWarning,
  setUnsavedMessage,
}) => {
  return (
    <li>
      <div className="placeholder">
        <div className="upside">
          <a href="#" className="button">
            <Tooltip title={"Új üzenet"}>
              <AddCircleIcon
                style={{
                  height: "45px",
                  width: "45px",
                  marginBottom: "-5px",
                }}
                className={
                  newMessageDialogOpen && openMessageDialog ? "active-icon" : ""
                }
                onClick={() => {
                  setNewMessageDialogOpen(true);
                  handleOpenNewMessageDialog(
                    setOpenMessageDialog,
                    setSelectedMessage,
                    setIsDialogLoading,
                    setTemplates,
                    setWarningMessage,
                    setOpenWarning,
                    setUnsavedMessage
                  );
                }}
              />
            </Tooltip>
          </a>
        </div>
      </div>
    </li>
  );
};

export default AddMessageMenuItem;
