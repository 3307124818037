import { Tooltip } from "@mui/material";
import { deleteFilters } from "../../utils/FilterActions";
import { useContext } from "react";
import { RefreshContext } from "../../utils/GlobalContexts";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import "./CircularMenu.css";
import { FilterDto } from "../../InfohubService";

interface Props {
  setFilterDtos: React.Dispatch<React.SetStateAction<FilterDto[]>>;
}

const DeleteFiltersMenuItem: React.FC<Props> = ({ setFilterDtos }) => {
  const { setRefresh } = useContext(RefreshContext);
  return (
    <li>
      <div className="placeholder">
        <div className="upside">
          <a href="#" className="button">
            <Tooltip title={"Szűrés törlése"}>
              <FilterAltOffIcon
                style={{
                  height: "42px",
                  width: "42px",
                  marginBottom: "-5px",
                  marginRight: "-20px",
                  fill: "#eb0202",
                }}
                onClick={() => {
                  deleteFilters(setFilterDtos, setRefresh);
                }}
              />
            </Tooltip>
          </a>
        </div>
      </div>
    </li>
  );
};

export default DeleteFiltersMenuItem;
