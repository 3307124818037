import { DialogContent, TextareaAutosize, Typography } from "@mui/material";
import DialogComponent from "./DialogComponent";
import {
  handleSaveNewTemplate,
  updateTemplate,
} from "../../utils/TemplateActions";
import { SetStateAction } from "react";
import { TemplateDto } from "../../InfohubService";

interface Props {
  openTemplateDialog: boolean;
  handleClose: () => void;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenTemplateDialog: React.Dispatch<React.SetStateAction<boolean>>;
  isDialogLoading: boolean;
  setOpenResults: React.Dispatch<SetStateAction<boolean>>;
  setResultMessage: React.Dispatch<SetStateAction<string>>;
  setOpenWarning: React.Dispatch<SetStateAction<boolean>>;
  setWarningMessage: React.Dispatch<SetStateAction<string>>;
  unsavedTemplate: string | null;
  setUnsavedTemplate: React.Dispatch<SetStateAction<string | null>>;
}

const TemplateDialog: React.FC<Props> = ({
  openTemplateDialog,
  handleClose,
  setIsDialogLoading,
  isDialogLoading,
  setUnsavedTemplate,
  setWarningMessage,
  setOpenWarning,
  setResultMessage,
  setOpenResults,
  setOpenTemplateDialog,
  unsavedTemplate,
}) => {
  return (
    <DialogComponent
      isDialogLoading={isDialogLoading}
      setIsDialogLoading={setIsDialogLoading}
      openMessageDialog={openTemplateDialog}
      handleSave={() =>
        handleSaveNewTemplate(
          unsavedTemplate,
          setOpenTemplateDialog,
          setOpenResults,
          setResultMessage,
          setOpenWarning,
          setWarningMessage,
          setIsDialogLoading,
          setUnsavedTemplate
        )
      }
      onClose={handleClose}
      title={"Template hozzáadása"}
      handleClose={handleClose}
      closeButtonText={"Mégse"}
    >
      <DialogContent style={{ paddingTop: 0 }}>
        <TextareaAutosize
          onChange={(event) => updateTemplate(event, setUnsavedTemplate)}
          style={{ width: "95%" }}
          defaultValue={`{
            "name": "string",
            "templateBody": "string",
            "dataScheme": {
              "linkedScheme": [
                {
                  "name": "string",
                  "type": "string",
                  "containerType": 0,
                  "containerName": "string"
                }
              ],
              "variablesScheme": [
                {
                  "key": "string",
                  "value": "string"
                }
              ]
            }
          }`}
        />
      </DialogContent>
    </DialogComponent>
  );
};

export default TemplateDialog;
