export const handleCloseResults = (
  setOpenResults: React.Dispatch<React.SetStateAction<boolean>>,
  setResultMessage: React.Dispatch<React.SetStateAction<string>>
) => {
  setOpenResults(false);
  setResultMessage("");
};

export const handleCloseWarning = (
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>,
  setResultWarning: React.Dispatch<React.SetStateAction<string>>
) => {
  setOpenWarning(false);
  setResultWarning("");
};
