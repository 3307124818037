/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TargetEntity } from '../models/TargetEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TargetService {

    /**
     * @param messageId 
     * @returns TargetEntity Success
     * @throws ApiError
     */
    public static getTargets(
messageId: string,
): CancelablePromise<Array<TargetEntity>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/targets/{messageId}',
            path: {
                'messageId': messageId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postTargetsMultiple(
requestBody?: Array<TargetEntity>,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/targets/multiple',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postTargetsUpdate(
requestBody?: Array<TargetEntity>,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/targets/update',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteTargets(
requestBody?: Array<string>,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/targets',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postTargetsSpecialEvent(
requestBody?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/targets/special-event',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
