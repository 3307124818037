import { MessageDto, TargetEntity, TargetService } from "../InfohubService";

export const handleTargetsEditClick = async (
  message: MessageDto,
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>,
  setOpenResults: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenTargets: React.Dispatch<React.SetStateAction<boolean>>,
  setResultMessage: React.Dispatch<React.SetStateAction<string>>,
  setSelectedMessageTargets: React.Dispatch<
    React.SetStateAction<TargetEntity[]>
  >,
  setSelectedMessageTargetsUpdated: React.Dispatch<
    React.SetStateAction<TargetEntity[]>
  >,
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>,
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>,
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (message?.rowKey) {
    setOpenResults(true);
    setIsDialogLoading(true);
    await TargetService.getTargets(message?.rowKey)
      .then((res) => {
        setSelectedMessageTargets(res as TargetEntity[]);
        setSelectedMessage(message);
        setSelectedMessageTargetsUpdated(res as TargetEntity[]);
        setOpenResults(false);
        setOpenTargets(true);
        setIsDialogLoading(false);
      })
      .catch((e) => {
        setWarningMessage("Nem sikerült a targetek betöltése.");
        setOpenResults(false);
        setOpenWarning(true);
        setIsDialogLoading(false);
      });
  }
};

export const handleCloseTargets = async (
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>,
  setSelectedMessageTargets: React.Dispatch<
    React.SetStateAction<TargetEntity[]>
  >,
  setUnsavedSelectedMessageTargets: React.Dispatch<
    React.SetStateAction<TargetEntity[]>
  >,
  setOpenTargets: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setSelectedMessage(null);
  setSelectedMessageTargets([] as TargetEntity[]);
  setUnsavedSelectedMessageTargets([] as TargetEntity[]);
  setOpenTargets(false);
};
