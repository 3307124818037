import { Pagination } from "@mui/material";

interface TablePaginationProps {
  totalCount: number;
  page: number;
  handleChangePage: () => void;
}

const TablePagination: React.FC<TablePaginationProps> = ({
  totalCount,
  page,
  handleChangePage,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: 0,
        backgroundColor: "white",
      }}
    >
      <Pagination
        sx={{
          ".MuiPagination-ul": {
            ".Mui-selected": {
              backgroundColor: "rgba(170, 202, 249, 0.8) !important",
            },
          },
        }}
        count={Math.ceil(totalCount / 10)}
        page={page === 0 ? 1 : page}
        onChange={handleChangePage}
      />
      <p style={{ marginLeft: "10px", fontSize: 14 }}>
        Összesen: {totalCount} üzenet.
      </p>
    </div>
  );
};

export default TablePagination;
