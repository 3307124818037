import { MsalAuthenticationResult } from "@azure/msal-react";
import { Fragment, PropsWithChildren, useState } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { INTERNAL_ROUTE } from "../../utils/GlobalEnums";
import { LoadingScreen } from "../loading/LoadingScreen";
import { loginRequest } from "../../utils/auth/authProvider";
import { Navigate } from "react-router-dom";

const LoginPage = () => {
  const [error, setError] = useState(null);

  const ErrorComponent = (
    result: PropsWithChildren<MsalAuthenticationResult>
  ) => {
    return error && <div>{result.error?.errorMessage}</div>;
  };

  function LoadingComponent() {
    return <LoadingScreen />;
  }

  return (
    <Fragment>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <Navigate to={INTERNAL_ROUTE.MESSAGES} />
      </MsalAuthenticationTemplate>
    </Fragment>
  );
};

export default LoginPage;
