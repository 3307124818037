import { useContext, useEffect, useState } from "react";
import {
  MessageService,
  MessageDto,
  MessagesParamDto,
  TemplateDto,
  TemplateService,
  FilterDto,
} from "../InfohubService";
import MessageTableView from "../components/table/MessageTableView";
import { RefreshContext } from "../utils/GlobalContexts";
import { Box } from "@mui/material";
import {
  convertIdsToProgramNames,
  retrieveProfileInfo,
  retrieveProfilePhoto,
} from "../utils/HelperFunctions";
import { productIds, programTypes } from "../utils/auth/Constants";
import ProfileInfoBox from "../components/profile-info/ProfileInfoBox";
import { ProfileInfo } from "../models/ProfileInfo";
import {
  acquireGraphApiToken,
  handleGraphApiLogin,
} from "../utils/auth/authActions";
import PageTitle from "../components/page-title/PageTitle";

const MessageListPage = () => {
  const [items, setItems] = useState([] as MessageDto[]);
  const [templates, setTemplates] = useState([] as TemplateDto[]);
  const { refresh, setRefresh } = useContext(RefreshContext);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [filterDtos, setFilterDtos] = useState([] as FilterDto[]);
  const [activeMessagesClicked, setActiveMessagesClicked] = useState(false);
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);
  const [profileInfo, setProfileInfo] = useState<ProfileInfo | null>(null);

  useEffect(() => {
    const fetchPhoto = async () => {
      let token = await acquireGraphApiToken();
      try {
        if (token === null) {
          await handleGraphApiLogin().then(async () => {
            token = await acquireGraphApiToken();
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        const imageUrl = await retrieveProfilePhoto(token);
        const profileinfo = await retrieveProfileInfo(token);
        setProfileInfo(profileinfo as any);
        setPhotoUrl(imageUrl);
      }
    };

    fetchPhoto();
  }, []);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    setIsLoading(true);
    getData();

    return () => {
      setItems([] as MessageDto[]);
      setTemplates([] as TemplateDto[]);
      setIsLoading(true);
      setPage(0);
      setRowsPerPage(10);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [page]);

  useEffect(() => {
    if (refresh) {
      setPage(0);
      getData();
      setRefresh(false);
    }
  }, [refresh]);

  const getData = async () => {
    setIsLoading(true);
    const params = {
      page: page === 0 ? 0 : page - 1,
      searchPhrase: searchPhrase,
      filterDtos: filterDtos.filter((dto) => dto.name && dto.value),
      filterActiveMessages: activeMessagesClicked,
    } as MessagesParamDto;

    await MessageService.postMessageFilter(params)
      .then((res) => {
        if (res !== undefined) {
          setTotalCount(res.totalCount ?? 0);
          if (res && res.messageDtos) {
            res.messageDtos.forEach((messageDto) => {
              if (messageDto.programTypes) {
                const programNames = convertIdsToProgramNames(
                  messageDto.programTypes ?? [],
                  programTypes
                );
                messageDto.programTypes = programNames;
              }
              if (messageDto.productIds) {
                const programNames = convertIdsToProgramNames(
                  messageDto.productIds ?? [],
                  productIds
                );
                messageDto.productIds = programNames;
              }
            });
            setItems(res?.messageDtos);
          }
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  return (
    <Box m={0} mt={0}>
      <PageTitle />
      <ProfileInfoBox profileInfo={profileInfo} photoUrl={photoUrl} />
      <MessageTableView
        searchPhrase={searchPhrase}
        setSearchPhrase={setSearchPhrase}
        filterDtos={filterDtos}
        setFilterDtos={setFilterDtos}
        messages={items}
        isLoading={isLoading}
        totalCount={totalCount}
        handleChangePage={handleChangePage}
        page={page}
        templates={templates}
        setActiveMessagesClicked={setActiveMessagesClicked}
        activeMessagesClicked={activeMessagesClicked}
        setTemplates={setTemplates}
      />
    </Box>
  );
};

export default MessageListPage;
