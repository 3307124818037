import { useEffect, useState } from "react";
import { LoadingScreen } from "../loading/LoadingScreen";
import styles from "./StatusPage.module.scss";
import Row from "../../components/Row";
import LogoPage from "../logo/LogoPage";

type Props = {
  title: string;
  description?: string;
  action?: React.ReactNode;
  withKSLogo: boolean;
};

const StatusPage = ({ title, description, action }: Props) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
      setIsLoading(true);
    };
  }, []);

  return isLoading ? <LoadingScreen /> : <LogoPage />;
};
export default StatusPage;
