import styled from "@emotion/styled";
import { FormControl, OutlinedInput } from "@mui/material";

export const StyledSearchBox = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: "#DCDCDC",
  color: "gray",
  fontWeight: "bold",
  height: "30px",
  margin: "5px",
  marginBottom: "15px",
}));

export const StyledSearch = styled(FormControl)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    color: "gray",
    borderRadius: "5px",
    boxShadow: "-5px 5px 2px 0px rgba(0, 0, 0, 0.53)",
    WebkitBoxShadow: "-5px 5px 2px 0px rgba(0, 0, 0, 0.53)",
    MozBoxShadow: "-5px 5px 2px 0px rgba(0, 0, 0, 0.53)",
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
  "&:hover": {
    backgroundColor: "transparent", // Change the background color to transparent
  },
}));
