import { Grid, Avatar, Box } from "@mui/material";
import { handleLogoutClick } from "../../utils/FilterActions";
import { LogoutButton } from "../button/StyledButton";
import KSLogo from "../../assets/profile/ks-logo.svg";
import { ProfileInfo } from "../../models/ProfileInfo";
import "./ProfileInfo.css";

interface Props {
  profileInfo: ProfileInfo | null;
  photoUrl: string | null;
}

const ProfileInfoBox: React.FC<Props> = ({ profileInfo, photoUrl }) => {
  return (
    <Box sx={{ position: "absolute", top: "10px", right: 20 }}>
      <Grid
        container
        sx={{
          position: "relative",
          borderRadius: 5,
          p: "0px 8px 0px 16px",

          overflow: "hidden",
          "&:hover": {
            backgroundColor: "rgb(0,119,255, 0.2)",
            cursor: "pointer",
            "& .profile-info": {
              opacity: 1,
              transform: "translateX(0)",
              transition: "opacity 0.3s ease, transform 0.3s ease",
            },
          },
          zIndex: 13,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          mt={0}
        >
          <Grid item xs="auto">
            <Grid
              container
              direction="column"
              className="profile-info"
              sx={{
                display: "flex",
                opacity: 0,
                transform: "translateX(100%)",
                transition: "opacity 0.3s ease, transform 0.3s ease",
              }}
            >
              <Grid item xs={12} mt={1} className="displayname">
                {profileInfo?.displayName}
              </Grid>
              <Grid item xs={12} className="jobTitle">
                {profileInfo?.jobTitle}
              </Grid>
              <Grid item xs="auto">
                <LogoutButton
                  onClick={handleLogoutClick}
                  variant="contained"
                  size="small"
                >
                  Kijelentkezés
                </LogoutButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Avatar
              alt="Remy Sharp"
              src={photoUrl ?? KSLogo}
              sx={{
                width: 70,
                height: 70,
                transition: "opacity 0.2s ease",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileInfoBox;
