import {
  DialogContent,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DialogComponent from "./DialogComponent";
import { SpecialEventDto, SpecialEventService } from "../../InfohubService";
import { StyledTextField } from "../textfield/StyledTextfield";

interface EditSpecialEventDialogProps {
  openEditSpecialEventDialog: boolean;
  handleCloseEditSpecialEventDialog: () => void;
  selectedSpecialEventDto: SpecialEventDto | null;
  setSelectedSpecialEventDto: React.Dispatch<
    React.SetStateAction<SpecialEventDto | null>
  >;
  setSpecEvents: React.Dispatch<React.SetStateAction<SpecialEventDto[] | null>>;
  setResultMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenResults: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isDialogLoading: boolean;
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditSpecialEventDialog: React.FC<EditSpecialEventDialogProps> = ({
  openEditSpecialEventDialog,
  handleCloseEditSpecialEventDialog,
  selectedSpecialEventDto,
  setSelectedSpecialEventDto,
  setSpecEvents,
  setResultMessage,
  setOpenResults,
  setIsDialogLoading,
  isDialogLoading,
  setWarningMessage,
  setOpenWarning,
}) => {
  const handleSpecialEventEdit = (event: any) => {
    setSelectedSpecialEventDto((prev) => ({
      ...prev,
      [event?.target?.name]:
        event?.target?.name === "active"
          ? event?.target?.checked
          : event?.target?.value,
    }));
  };

  const handleSpecialEventEditSave = async () => {
    if (selectedSpecialEventDto) {
      const formdata = {
        PartitionKey: selectedSpecialEventDto?.partitionKey ?? "",
        Active: selectedSpecialEventDto?.active ?? false,
        Name: selectedSpecialEventDto?.name ?? "",
      };
      setOpenResults(true);
      setIsDialogLoading(true);
      await SpecialEventService.putSpecialevent(formdata)
        .then(async () => {
          await SpecialEventService.getSpecialevent1()
            .then((res) => {
              setSpecEvents(res);
            })
            .catch((e) => console.log(e));
          setResultMessage("A speciális esemény szerkesztése sikeres volt.");
          setIsDialogLoading(false);
        })
        .catch((e) => {
          setOpenResults(false);
          setOpenWarning(true);
          setWarningMessage(
            "A speciális esemény szerkesztése nem volt sikeres."
          );
          setIsDialogLoading(false);
        });
      handleCloseEditSpecialEventDialog();
    }
  };

  return (
    <DialogComponent
      isDialogLoading={isDialogLoading}
      setIsDialogLoading={setIsDialogLoading}
      openMessageDialog={openEditSpecialEventDialog}
      onClose={handleCloseEditSpecialEventDialog}
      title={"Speciális esemény szerkesztése"}
      handleClose={handleCloseEditSpecialEventDialog}
      handleSave={handleSpecialEventEditSave}
      closeButtonText={"Mégse"}
      saveButtonText={"Mentés"}
    >
      <DialogContent style={{ paddingTop: 0 }}>
        <StyledTextField
          label="Név"
          name="name"
          value={selectedSpecialEventDto?.name ?? ""}
          onChange={handleSpecialEventEdit}
          fullWidth
          margin="dense"
          size="small"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="active"
              onChange={handleSpecialEventEdit}
              checked={selectedSpecialEventDto?.active ?? false}
            />
          }
          label="Aktív"
        />
      </DialogContent>
    </DialogComponent>
  );
};

export default EditSpecialEventDialog;
