// src/components/PageFooter.tsx

import React from "react";
import Typography from "@mui/material/Typography";
import "./PageFooter.css";
import { ReactComponent as KSLogo } from "../../assets/profile/ks-logo-text.svg";

const PageFooter: React.FC = () => {
  return (
    <footer className={"footer"}>
      <KSLogo className="logo-footer" />
      <Typography
        variant="body2"
        sx={{ fontWeight: 300, fontSize: 13, color: "#0f2341" }}
      >
        © 2023 Kulcs-Soft Nyrt.
      </Typography>
    </footer>
  );
};

export default PageFooter;
