import styled from "@emotion/styled";
import { Button, css } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DCDCDC",
  color: "gray",
  fontWeight: "bold",
  borderRadius: "5px",
  border: "0px solid gray",
  height: "30px",
  boxShadow: "-5px 5px 2px 0px rgba(0, 0, 0, 0.53)",
  WebkitBoxShadow: "-5px 5px 2px 0px rgba(0, 0, 0, 0.53)",
  MozBoxShadow: "-5px 5px 2px 0px rgba(0, 0, 0, 0.53)",
  "&:hover": {
    backgroundColor: "rgba(25, 118, 210, 0.2)",
  },
  margin: "5px",
  marginBottom: "15px",
}));

export const LogoutButton = styled(StyledButton)(({ theme }) =>
  css({
    backgroundColor: "rgb(237,2,2, 0.5)",
    color: "#ffffff",
    border: "2px solid rgb(189,2,2, 0)",
    "&:hover": {
      backgroundColor: "rgb(237,2,2, 0.6)",
    },
  })
);
