import styled from "@emotion/styled";
import { TableContainer } from "@mui/material";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  ["&::-webkit-scrollbar"]: {
    width: 7,
  },
  ["&::-webkit-scrollbar-track"]: {
    backgroundColor: "#f2f5fa",
  },
  ["&::-webkit-scrollbar-thumb"]: {
    backgroundImage: "white",
    borderRadius: 7,
  },
  borderRadius: "10px",
  boxShadow: "5px 8px 8px #ECECEC",
  maxHeight: "calc(100vh - 180px)",
  marginRight: "20px",
  marginLeft: "20px",
  width: "auto",
}));
