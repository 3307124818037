import { IPublicClientApplication } from "@azure/msal-browser";
import {
  graphAPIRequest,
  loginRequest,
  msalConfig,
  msalInstance,
} from "./authProvider";
import Cookies from "universal-cookie";
import { LOCAL_STORAGE_KEY } from "../GlobalEnums";

export const handleLogin = async (cookies: Cookies) => {
  await msalInstance
    .handleRedirectPromise()
    .then(
      (
        res //reject??
      ) => {
        msalInstance.loginRedirect(loginRequest).catch(() => {
          localStorage.clear();
          sessionStorage.clear();
          cookies.remove("msal.interaction.status");
        });
      }
    )
    .catch((error) => {
      if (error.errorCode === "server_error") {
        error.errorMessage.includes("AADB2C99002")
          ? console.log(error.errorMessage)
          : console.log(error.errorMessage);
      } else console.log(error.errorMessage);
    });
};

export const handleGraphApiLogin = async () => {
  await msalInstance.loginRedirect(graphAPIRequest);
  await msalInstance
    .handleRedirectPromise()
    .then(() => {
      msalInstance.loginRedirect(graphAPIRequest).catch((e) => {
        console.log(e);
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const handleLogout = () => {
  const logoutRequest = {
    account: msalInstance.getActiveAccount(),
    postLogoutRedirectUri: process.env.REACT_APP_SELF_BASE_URL,
  };

  msalInstance
    .logoutRedirect(logoutRequest)
    .then(() => {
      localStorage.removeItem(LOCAL_STORAGE_KEY.MS_AUTH_TOKEN);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const acquireToken = async () => {
  const request = {
    ...loginRequest,
    account: msalInstance.getAllAccounts()[0],
  };
  return msalInstance
    .acquireTokenSilent(request)
    .then((response) => {
      return response.accessToken;
    })
    .catch(() => {
      msalInstance.acquireTokenRedirect(request).catch((error) => {
        handleLogout();
      });
    });
};

export const acquireGraphApiToken = async () => {
  const request = {
    ...graphAPIRequest,
    account: msalInstance.getAllAccounts()[0],
  };
  return msalInstance
    .acquireTokenSilent(request)
    .then((response) => {
      return response.accessToken;
    })
    .catch(() => {
      msalInstance.acquireTokenRedirect(request).catch((error) => {
        return null;
      });
    });
};
