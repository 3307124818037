import React, { SetStateAction } from "react";
import { TemplateDto, TemplateService } from "../InfohubService";

export const handleCloseTemplateDialog = (
  setOpenTemplateDialog: React.Dispatch<SetStateAction<boolean>>
) => {
  setOpenTemplateDialog(false);
};

export const handleSaveNewTemplate = async (
  template: string | null,
  setOpenTemplateDialog: React.Dispatch<SetStateAction<boolean>>,
  setOpenResults: React.Dispatch<SetStateAction<boolean>>,
  setResultMessage: React.Dispatch<SetStateAction<string>>,
  setOpenWarning: React.Dispatch<SetStateAction<boolean>>,
  setWarningMessage: React.Dispatch<SetStateAction<string>>,
  setIsDialogLoading: React.Dispatch<SetStateAction<boolean>>,
  setUnsavedTemplate: React.Dispatch<SetStateAction<string | null>>
) => {
  setOpenResults(true);
  setIsDialogLoading(true);
  if (template)
    await TemplateService.postTemplates(JSON.parse(template))
      .then(() => {
        setOpenResults(true);
        setResultMessage("A template hozzáadása sikeres volt.");
        setOpenTemplateDialog(false);
        setIsDialogLoading(false);
        setUnsavedTemplate(null);
      })
      .catch(() => {
        setOpenWarning(true);
        setOpenResults(false);
        setWarningMessage("A template hozzáadása nem sikerült.");
        setIsDialogLoading(false);
      });
  else {
    setOpenWarning(true);
    setOpenResults(false);
    setWarningMessage("A template hozzáadása nem sikerült.");
    setIsDialogLoading(false);
  }
};

export const updateTemplate = (
  event: any,
  setUnsavedTemplate: React.Dispatch<SetStateAction<string | null>>
) => setUnsavedTemplate(event?.target.value);
