import styled from "@emotion/styled";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Fade,
} from "@mui/material";
import { StyledButton } from "../button/StyledButton";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  // Custom styles for the Dialog
  "& .MuiDialog-paper": {
    borderRadius: "10px",
  },
}));

interface DialogComponentProps {
  openMessageDialog: boolean;
  onClose: () => void;
  title: string;
  handleClose?: any;
  handleSave?: any;
  closeButtonText?: string;
  saveButtonText?: string;
  isDialogLoading: boolean;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  children: JSX.Element;
}

const DialogComponent: React.FC<DialogComponentProps> = ({
  openMessageDialog,
  onClose,
  title,
  handleClose,
  handleSave,
  closeButtonText,
  saveButtonText,
  isDialogLoading,
  setIsDialogLoading,
  children,
}) => {
  const handleSaveClick = () => {
    handleSave();
  };

  const handleCloseClick = () => {
    handleClose();
    setIsDialogLoading(false);
  };

  const handleOnCloseEvent = (e: any) => {
    onClose();
    setIsDialogLoading(false);
  };

  return (
    <StyledDialog open={openMessageDialog} onClose={handleOnCloseEvent}>
      <Box
        minWidth={isDialogLoading ? "350px" : "500px"}
        height={"100%"}
        sx={{ overflowX: "hidden", overflowY: "hidden" }}
        display="flex"
        flexDirection="column"
      >
        {!isDialogLoading && (
          <DialogTitle
            sx={{
              backgroundColor: title ? "rgb(0,119,255, 0.2)" : "transparent",
              p: 1,
              mb: 1,
              borderBottom: title
                ? "7px solid rgb(0,119,255, 0.4)"
                : "transparent",
            }}
          >
            {title}
          </DialogTitle>
        )}
        <Box
          sx={{ overflowX: "hidden", overflowY: "auto" }}
          flex="1"
          overflow="auto"
        >
          <Box maxHeight={"70vh"}>
            {isDialogLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "20vh",
                }}
              >
                <CircularProgress size={100} />{" "}
              </Box>
            ) : (
              children
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" mt={0}>
          {!isDialogLoading && (
            <DialogActions>
              {handleSave && (
                <StyledButton
                  style={{
                    backgroundColor: "rgba(70, 235, 179, 0.3)",
                  }}
                  size="small"
                  onClick={handleSaveClick}
                  variant="contained"
                  color="primary"
                >
                  {saveButtonText ?? "Mentés"}
                </StyledButton>
              )}
              {handleClose && (
                <StyledButton
                  style={{
                    backgroundColor:
                      closeButtonText === "OK"
                        ? "rgba(170, 202, 249, 0.6)"
                        : "rgba(250, 62, 62, 0.6)",
                  }}
                  size="small"
                  variant="contained"
                  onClick={handleCloseClick}
                >
                  {closeButtonText ?? "Mégse"}
                </StyledButton>
              )}
            </DialogActions>
          )}
        </Box>
      </Box>
    </StyledDialog>
  );
};

export default DialogComponent;
