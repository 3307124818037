import { Fragment, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import styles from "./LogoPage.module.scss";
import { InteractionStatus } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";
import { INTERNAL_ROUTE } from "../../utils/GlobalEnums";
import Row from "../../components/Row";
import { Box, Button, styled } from "@mui/material";
import { StyledButton } from "../../components/button/StyledButton";
import AppTitle from "../../components/app-title/AppTitle";

const LogoPage = () => {
  const navigate = useNavigate();
  const [showButtons, setShowButtons] = useState(false);
  const { accounts, inProgress } = useMsal();
  let userCount = accounts.length;

  useEffect(() => {
    let action = () => console.log();
    let timer = setTimeout(() => {
      action();
    }, 1500);
    if (inProgress === InteractionStatus.None) {
      if (userCount > 0) action = () => navigate(INTERNAL_ROUTE.MESSAGES);
      else action = () => setShowButtons(true);
    }

    return () => clearTimeout(timer);
  }, [inProgress]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column", // Display items in a column layout
        justifyContent: "center",
        alignItems: "center",
        height: "70vh", // Set the height of the container to fill the viewport
      }}
    >
      <AppTitle />
      <div className={`${showButtons ? styles["buttons"] : styles["none"]}`}>
        <Row className="large-column-spacing default-bottom-distance">
          <StyledButton
            onClick={() => navigate(INTERNAL_ROUTE.LOGIN)}
            sx={{ height: "80px", padding: "20px", fontSize: "24px" }}
          >
            Bejelentkezés
          </StyledButton>
        </Row>
      </div>
    </Box>
  );
};
export default LogoPage;
