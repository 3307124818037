import { DialogContent } from "@mui/material";
import DialogComponent from "./DialogComponent";
import { MessageDto, MessageService } from "../../InfohubService";
import { useContext, useEffect } from "react";
import { RefreshContext } from "../../utils/GlobalContexts";

interface ConfirmDeleteMessageDialogProps {
  openConfirmDeleteMessageDialog: boolean;
  handleCloseConfirmDeleteMessageDialog: () => void;
  selectedMessage: MessageDto | null;
  setResultMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenResults: React.Dispatch<React.SetStateAction<boolean>>;
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isDialogLoading: boolean;
}

const ConfirmDeleteMessageDialog: React.FC<ConfirmDeleteMessageDialogProps> = ({
  openConfirmDeleteMessageDialog,
  handleCloseConfirmDeleteMessageDialog,
  selectedMessage,
  setResultMessage,
  setOpenResults,
  setOpenWarning,
  setWarningMessage,
  setIsDialogLoading,
  isDialogLoading,
}) => {
  const { setRefresh } = useContext(RefreshContext);

  const handleDeleteMessage = async () => {
    if (selectedMessage?.partitionKey) {
      setOpenResults(true);
      setIsDialogLoading(true);
      await MessageService.deleteMessage(selectedMessage?.partitionKey)
        .then((res) => {
          setRefresh(true);
          setResultMessage("Az üzenet törlése sikeres volt.");
          handleCloseConfirmDeleteMessageDialog();
          setOpenResults(true);
          setIsDialogLoading(false);
        })
        .catch((e) => {
          setOpenResults(false);
          setWarningMessage("Az üzenet törlése nem sikerült.");
          setOpenWarning(true);
          setIsDialogLoading(false);
        });
    }
  };
  return (
    <DialogComponent
      isDialogLoading={isDialogLoading}
      setIsDialogLoading={setIsDialogLoading}
      openMessageDialog={openConfirmDeleteMessageDialog}
      onClose={handleCloseConfirmDeleteMessageDialog}
      title={"Figyelmeztetés"}
      handleClose={handleCloseConfirmDeleteMessageDialog}
      closeButtonText={"Mégse"}
      handleSave={handleDeleteMessage}
      saveButtonText={"Törlés"}
    >
      <DialogContent style={{ paddingTop: 0 }}>
        {"Biztosan törölni szeretné az üzenetet?"}
      </DialogContent>
    </DialogComponent>
  );
};

export default ConfirmDeleteMessageDialog;
