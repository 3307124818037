import { Clear, Search } from "@mui/icons-material";
import { Grid, InputAdornment, IconButton } from "@mui/material";
import { MessageDto, TemplateDto } from "../../InfohubService";
import { useContext, useEffect, useState } from "react";
import { RefreshContext } from "../../utils/GlobalContexts";
import {
  handleSearchEnter,
  deleteSearch,
  doSearch,
  handleSearchChange,
} from "../../utils/FilterActions";

import "./TableActionMenu.css";
import { StyledSearch, StyledSearchBox } from "../search/StyledSearch";
import { StyledButton } from "../button/StyledButton";
import { handleOpenNewMessageDialog } from "../../utils/MessageActions";

interface TableActionMenuProps {
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>;
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>;
  setUnsavedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>;
  setOpenMessageDialog: React.Dispatch<React.SetStateAction<boolean>>;
  openMessageDialog: boolean;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTemplates: React.Dispatch<React.SetStateAction<TemplateDto[]>>;
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableActionMenu: React.FC<TableActionMenuProps> = ({
  setIsDialogLoading,
  setSearchPhrase,
  setSelectedMessage,
  setOpenMessageDialog,
  openMessageDialog,
  setTemplates,
  setOpenWarning,
  setWarningMessage,
  setUnsavedMessage,
}) => {
  const { setRefresh } = useContext(RefreshContext);
  const [unsavedSearchPhrase, setUnsavedSearchPhrase] = useState("");
  const [newMessageDialogOpen, setNewMessageDialogOpen] = useState(false);

  useEffect(() => {
    if (!openMessageDialog) setNewMessageDialogOpen(false);
  }, [openMessageDialog]);

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      spacing={0}
      sx={{ height: "120px" }}
    >
      <Grid item xs={12}>
        <div
          className={"active"}
          style={{
            textAlign: "center",
            marginTop: "70px",
            marginLeft: "-150px",
          }}
        >
          <StyledButton
            size="small"
            sx={{
              backgroundColor: newMessageDialogOpen
                ? "rgba(25, 118, 210, 0.4) !important"
                : "#DCDCDC",
              width: "140px",
              fontSize: 15,
            }}
            onClick={() => {
              setNewMessageDialogOpen(true);
              handleOpenNewMessageDialog(
                setOpenMessageDialog,
                setSelectedMessage,
                setIsDialogLoading,
                setTemplates,
                setWarningMessage,
                setOpenWarning,
                setUnsavedMessage
              );
            }}
          >
            Új üzenet
          </StyledButton>
          <StyledSearch>
            <StyledSearchBox
              size="small"
              value={unsavedSearchPhrase}
              onChange={(event) =>
                handleSearchChange(event, setUnsavedSearchPhrase)
              }
              onKeyDown={(event) =>
                handleSearchEnter(
                  event,
                  setSearchPhrase,
                  setRefresh,
                  unsavedSearchPhrase
                )
              }
              placeholder="Keresés az üzenetekben..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      unsavedSearchPhrase !== ""
                        ? deleteSearch(
                            setUnsavedSearchPhrase,
                            setSearchPhrase,
                            setRefresh
                          )
                        : doSearch(
                            setSearchPhrase,
                            setRefresh,
                            unsavedSearchPhrase
                          )
                    }
                    edge="end"
                  >
                    {unsavedSearchPhrase !== "" ? <Clear /> : <Search />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </StyledSearch>
        </div>
      </Grid>
    </Grid>
  );
};

export default TableActionMenu;
