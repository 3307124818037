import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import MessageListPage from "./pages/MessageListPage";
import { INTERNAL_ROUTE } from "./utils/GlobalEnums";
import LoginPage from "./pages/login/LoginPage";
import RequireUser from "./utils/auth/RequireUser";
import LogoPage from "./pages/logo/LogoPage";
import { LoadingScreen } from "./pages/loading/LoadingScreen";

function App() {
  return (
    <Router>
      <Routes>
        <Route path={INTERNAL_ROUTE.START} element={<LogoPage />} />
        <Route path={INTERNAL_ROUTE.LOADING} element={<LoadingScreen />} />
        <Route path={INTERNAL_ROUTE.LOGIN} element={<LoginPage />} />
        <Route
          path={INTERNAL_ROUTE.MESSAGES}
          element={
            <RequireUser>
              <MessageListPage />
            </RequireUser>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
