import {
  DataObject,
  LinkedItem,
  MessageDto,
  MessageService,
  StringStringKeyValuePair,
  TemplateDto,
  TemplateService,
} from "../InfohubService";
import { ChipData } from "../components/dialog/ChipsArray";
import {
  convertIdsToProgramNames,
  convertProgramNamesToIds,
  generateRandomPastelColor,
} from "./HelperFunctions";
import { programTypes, productIds } from "./auth/Constants";
import _, { isEqual } from "lodash";

export const getExistingTemplateVariables = async (
  selectedMessage: MessageDto | null,
  templates: TemplateDto[],
  setChips: React.Dispatch<React.SetStateAction<ChipData[]>>
) => {
  if (selectedMessage?.template) {
    if (selectedMessage?.template !== "-1") setChips([]);
    var templateDataScheme = templates.filter(
      (e) => e.name === selectedMessage?.template
    )[0]?.dataScheme;

    var existingdatavariables = selectedMessage?.data?.variables;
    var existingdatalinked = selectedMessage?.data?.linked;

    if (templateDataScheme) {
      var stringValues =
        templateDataScheme.variablesScheme?.map(
          (item) =>
            ({
              label: item.key,
              type: "szöveg",
              value:
                existingdatavariables?.find((x) => x.key === item.key)?.value ??
                "",
            } as ChipData)
        ) ?? [];

      var linkedvalues =
        templateDataScheme.linkedScheme?.map((item) => ({
          label: item.name,
          type:
            item.type === "video"
              ? "video"
              : item.type === "rss"
              ? "rss"
              : "kép",
          value: null,
          file: existingdatalinked?.find((x) => x.name === item.name)?.file,
        })) ?? [];

      let chipVariables =
        [...stringValues, ...linkedvalues].map(
          (item, index) =>
            ({
              label: item.label,
              key: index,
              type: item.type,
              color: generateRandomPastelColor(),
              value: item.value,
              file: item.file,
            } as ChipData)
        ) ?? [];

      await setChips(chipVariables);
    } else await setChips([]);
  }
};

const createMessageDtoForm = (
  type: "create" | "update",
  selectedMessage: MessageDto | null,
  chips: ChipData[]
) => {
  const filteredArray: StringStringKeyValuePair[] =
    chips
      ?.filter((chipData) => chipData?.type === "szöveg")
      .map((chipData) => ({
        key: chipData.label,
        value: chipData.value,
      })) ?? [];
  const linkedArray: LinkedItem[] =
    chips
      ?.filter((chipData) => chipData?.type !== "szöveg")
      .map((chipData) =>
        chipData?.type === "kép"
          ? {
              name: chipData?.label,
              containerType: 0,
              containerName: "images",
              filename: chipData?.file?.name,
            }
          : chipData?.type === "video"
          ? {
              name: chipData?.label,
              containerType: 1,
              containerName: "videos",
              filename: chipData?.file?.name,
            }
          : {
              name: chipData?.label,
              containerType: 2,
              containerName: "rss",
            }
      ) ?? [];
  const fileinfos: any[] =
    chips
      ?.filter(
        (chipData) =>
          (chipData?.type === "kép" || chipData?.type === "video") &&
          chipData?.file !== null
      )
      .map((chipData) => ({
        filename: chipData?.file?.name,
        name: chipData?.label,
      })) ?? [];

  const files: File[] = (chips ?? [])
    .filter(
      (chipData) =>
        (chipData?.type === "kép" || chipData?.type === "video") &&
        chipData?.file !== null
    )
    .map((chipData) => chipData?.file as File);

  let dataobj = {
    linked: linkedArray,
    variables: filteredArray,
  } as DataObject;

  switch (type) {
    case "create":
      return {
        Template: selectedMessage?.template as string,
        IsActive: selectedMessage?.isActive ?? false,
        IsDynamic: selectedMessage?.isDynamic ?? false,
        DataJson: JSON.stringify(dataobj),
        Files: files,
        FileInfos: JSON.stringify(fileinfos),
        Title: selectedMessage?.title as string,
        ProgramTypes: convertProgramNamesToIds(
          selectedMessage?.programTypes ?? [],
          programTypes
        ) as string[],
        ProductIds: convertProgramNamesToIds(
          selectedMessage?.productIds ?? [],
          productIds
        ) as string[],
      };
    case "update":
      return {
        PartitionKey: selectedMessage?.partitionKey as string,
        Template: selectedMessage?.template as string,
        IsActive: selectedMessage?.isActive ?? false,
        IsDynamic: selectedMessage?.isDynamic ?? false,
        DataJson: JSON.stringify(dataobj),
        Files: files,
        FileInfos: JSON.stringify(fileinfos),
        Title: selectedMessage?.title as string,
        ProgramTypes: convertProgramNamesToIds(
          selectedMessage?.programTypes ?? [],
          programTypes
        ) as string[],
        ProductIds: convertProgramNamesToIds(
          selectedMessage?.productIds ?? [],
          productIds
        ) as string[],
      };
    default:
      return {};
  }
};

export const handleSaveMessageChanges = async (
  setOpenMessageDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>,
  selectedMessage: MessageDto | null,

  setResultMessage: React.Dispatch<React.SetStateAction<string>>,
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenResults: React.Dispatch<React.SetStateAction<boolean>>,
  chips: ChipData[],
  setChips: React.Dispatch<React.SetStateAction<ChipData[]>>,
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>,
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>,
  setUnsavedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>,
  unsavedMessage: MessageDto | null
) => {
  if (
    unsavedMessage?.title &&
    unsavedMessage?.template &&
    unsavedMessage?.template !== "-1"
  ) {
    setOpenResults(true);
    setIsDialogLoading(true);
    if (unsavedMessage?.partitionKey) {
      const updateForm = createMessageDtoForm("update", unsavedMessage, chips);
      await MessageService.putMessage(updateForm)
        .then(() => {
          setResultMessage("Az üzenet szerkesztése sikeres volt.");
          setRefresh(true);
          handleMessageClose(
            setOpenMessageDialog,
            setSelectedMessage,
            setChips,
            setUnsavedMessage
          );
          setIsDialogLoading(false);
        })
        .catch((e) => {
          setWarningMessage("Az üzenet szerkesztése nem sikerült.");
          setOpenWarning(true);
          setOpenResults(false);
          setIsDialogLoading(false);
        });
    } else {
      const createForm = createMessageDtoForm("create", unsavedMessage, chips);

      await MessageService.postMessage(createForm)
        .then(() => {
          setResultMessage("Az üzenet hozzáadása sikeres volt.");
          handleMessageClose(
            setOpenMessageDialog,
            setSelectedMessage,
            setChips,
            setUnsavedMessage
          );
          setRefresh(true);
          setIsDialogLoading(false);
        })
        .catch((e) => {
          setWarningMessage("Az üzenet hozzáadása nem sikerült.");
          setOpenWarning(true);
          setOpenResults(false);
          setIsDialogLoading(false);
        });
    }
  } else {
    setOpenWarning(true);
    setOpenResults(false);
    setIsDialogLoading(false);
    setWarningMessage(
      `A mezők kitöltése kötelező! (${getMissingFieldNames(selectedMessage)})`
    );
  }
};

export const handleMessageClose = (
  setOpenMessageDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>,
  setChips: React.Dispatch<React.SetStateAction<ChipData[]>>,
  setUnsavedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>
) => {
  setOpenMessageDialog(false);
  setSelectedMessage(null);
  setUnsavedMessage(null);
  setChips([]);
};

export const handleMessageDataEdit = async (
  event: any,
  setUnsavedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>
) => {
  setUnsavedMessage((prev) => ({
    ...prev,
    data: {
      ...prev?.data,
      [event?.target.name]:
        event?.target?.name === "groups"
          ? event?.target.value.split(",")
          : event?.target.value,
    },
  }));
};

export const handleMessageEdit = async (
  event: any,
  setUnsavedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>
) => {
  setUnsavedMessage((prev) => ({
    ...prev,

    [event?.target.name]:
      event?.target?.name === "isActive" || event?.target?.name === "isDynamic"
        ? event?.target?.checked
        : event?.target.value,
  }));
};

export const handleEditMessageClick = async (
  partitionKey: string | null | undefined,
  setOpenMessageDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>,
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>,
  setTemplates: React.Dispatch<React.SetStateAction<TemplateDto[]>>,
  setUnsavedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>,
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>
) => {
  if (partitionKey) {
    setOpenMessageDialog(true);
    setIsDialogLoading(true);
    await TemplateService.getTemplates()
      .then((res) => {
        setTemplates(res);
      })
      .catch((e) => {
        setWarningMessage("A template-ek betöltése nem sikerült.");
        setOpenWarning(true);
      });

    await MessageService.getMessage(partitionKey)
      .then((res) => {
        var programTypeArray = convertIdsToProgramNames(
          res?.programTypes ?? [],
          programTypes
        );
        res.programTypes = programTypeArray;
        var productIdsArray = convertIdsToProgramNames(
          res?.productIds ?? [],
          productIds
        );
        res.productIds = productIdsArray;
        setSelectedMessage(res);
        setUnsavedMessage(_.cloneDeep(res));
        setIsDialogLoading(false);
      })
      .catch(() => {
        setOpenMessageDialog(false);
        setWarningMessage("Nem sikerült az üzenet betöltése.");
        setOpenWarning(true);
        setIsDialogLoading(false);
      });
  }
};

export const handleDeleteMessageClick = async (
  message: MessageDto,
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>,
  setOpenConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (message) {
    setSelectedMessage(message);
    setOpenConfirmDialog(true);
    setIsDialogLoading(false);
  }
};

export const handleCloseConfirmDeleteMessageDialog = (
  setOpenConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setOpenConfirmDialog(false);
};

export const handleOpenNewMessageDialog = async (
  setOpenMessageDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>,
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setTemplates: React.Dispatch<React.SetStateAction<TemplateDto[]>>,
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>,
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>,
  setUnsavedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>
) => {
  setIsDialogLoading(true);
  setOpenMessageDialog(true);
  await TemplateService.getTemplates()
    .then((res) => {
      setTemplates(res);
      setIsDialogLoading(false);
    })
    .catch((e) => {
      setWarningMessage("A template-ek betöltése nem sikerült.");
      setOpenWarning(true);
      setIsDialogLoading(false);
    });
  setSelectedMessage({} as MessageDto);
  setUnsavedMessage({} as MessageDto);
};
function getMissingFieldNames(selectedMessage: MessageDto | null) {
  let missing: string[] = [];
  if (!selectedMessage?.title) missing.push("cím");
  if (!selectedMessage?.template || selectedMessage?.template === "-1")
    missing.push("template");
  return missing.join(", ");
}
