import "./AppTitle.css";

const AppTitle = () => {
  return (
    <header className="header-app">
      <hgroup>
        <h1>Info-Hub</h1>
        <h2>Beúszó Manager</h2>
      </hgroup>
    </header>
  );
};

export default AppTitle;
