import axios from "axios";
import { ProductId, ProgramType } from "./auth/Constants";
import { MessageDto } from "../InfohubService";
import { isEqual } from "lodash";

export const cutLongString = (string: string, limit: number) => {
  let editedString = "";
  if (string.length > limit) {
    editedString = string.substring(0, limit) + "...";
  }
  return editedString.length > 0 ? editedString : string;
};

export function encodeFileToBase64(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result?.toString()?.split(",")[1];
      if (base64String) {
        resolve(base64String);
      } else {
        reject(new Error("Failed to encode file to base64."));
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

// export const getEnumText = (enumtype: KSContentType) => {
//   switch (enumtype) {
//     case KSContentType._0:
//       return "Kép";
//     case KSContentType._1:
//       return "RSS feed";
//     default:
//       return "";
//   }
// };

export const formatDate = (date: Date | null): string => {
  if (date) {
    const formattedDate = new Date(date);

    const year = formattedDate.getFullYear();
    const month = ("0" + (formattedDate.getMonth() + 1)).slice(-2);
    const day = ("0" + formattedDate.getDate()).slice(-2);
    const hours = ("0" + formattedDate.getHours()).slice(-2);
    const minutes = ("0" + formattedDate.getMinutes()).slice(-2);

    return `${year}.${month}.${day}. ${hours}:${minutes}`;
  }
  return "N/A";
};

export function generateRandomPastelColor(): string {
  const MAX_COLOR_VALUE = 256;
  const MIN_LIGHTNESS = 0.8;
  const MAX_LIGHTNESS = 0.9;
  const SATURATION = 0.8;

  const hue = Math.floor(Math.random() * MAX_COLOR_VALUE);
  const lightness =
    Math.random() * (MAX_LIGHTNESS - MIN_LIGHTNESS) + MIN_LIGHTNESS;

  const color = `hsl(${hue}, ${SATURATION * 100}%, ${lightness * 100}%)`;
  return color;
}

export const convertProgramNamesToIds = (
  programNames: string[],
  array: ProductId[] | ProgramType[]
): string[] => {
  const productIds: string[] = [];

  programNames.forEach((programName) => {
    const program = array.find(
      (programType) => programType.productName === programName
    );

    if (program) {
      productIds.push(program.id);
    }
  });

  return productIds;
};

export const convertIdsToProgramNames = (
  productIds: string[],
  array: ProductId[] | ProgramType[]
): string[] => {
  const programNames: string[] = [];

  productIds.forEach((productId) => {
    const program = array.find((programType) => programType.id === productId);

    if (program) {
      programNames.push(program.productName);
    }
  });

  return programNames;
};

export const formatFileSize = (
  sizeInBytes: number,
  format: string = "KB"
): string => {
  const units: { [key: string]: number } = {
    B: 0,
    KB: 1,
    MB: 2,
    GB: 3,
    TB: 4,
  };

  let fileSize = sizeInBytes;
  let unit = "B";

  while (fileSize >= 1024 && units[unit] < units[format]) {
    fileSize /= 1024;
    unit = Object.keys(units)[units[unit] + 1];
  }

  return fileSize.toFixed(3) + " " + unit;
};

export async function retrieveProfilePhoto(token: string | void) {
  try {
    const response = await axios.get(
      "https://graph.microsoft.com/v1.0/me/photo/$value",
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error retrieving profile photo:", error);
    return null;
  }
}

export async function retrieveProfileInfo(token: string | void) {
  try {
    const response = await axios.get("https://graph.microsoft.com/v1.0/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error retrieving profile photo:", error);
    return null;
  }
}
