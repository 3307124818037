import {
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  css,
  styled,
} from "@mui/material";
import DialogComponent from "./DialogComponent";
import {
  MessageDto,
  SpecialEventDto,
  TargetEntity,
  TargetService,
} from "../../InfohubService";
import { StyledSelect } from "../select/StyledSelect";
import { useEffect } from "react";

const labelStyles = css`
  margin-bottom: 3px;
  line-height: 1;
  color: #c4c4c4;

  &.Mui-focused {
    margin: 0;
    font-size: 15px;
    color: rgb(0, 119, 255, 0.2);
  };
  &.Mui-shrink: {
    font-size: 10; 
    margin: 0;
  },
`;

const formControlStyles = css`
  width: 100%;
  margintop: 5px;

  & label.Mui-focused {
    color: rgb(0, 119, 255, 0.2); /* Set the desired label color when focused */
  }

  & .MuiInputBase-root {
    height: 30px;
    font-size: 14px;
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: rgb(
        0,
        119,
        255,
        0.2
      ); /* Set the desired border color when focused */
    }
  }
`;

export const StyledFormControl = styled(FormControl)`
  ${formControlStyles}
`;

export const StyledInputLabel = styled(InputLabel)`
  ${labelStyles}
`;

interface MessageSpecialEventDialogProps {
  openConnectSpecialEventToMessageDialog: boolean;
  handleCloseConnectSpecialEventToMessageDialog: () => void;
  specEvents: SpecialEventDto[] | null;
  selectedSpecialEventId: string | null;
  setSelectedSpecialEventId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  selectedMessage: MessageDto | null;
  setResultMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenResults: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isDialogLoading: boolean;
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

const MessageSpecialEventDialog: React.FC<MessageSpecialEventDialogProps> = ({
  openConnectSpecialEventToMessageDialog,
  handleCloseConnectSpecialEventToMessageDialog,
  specEvents,
  selectedSpecialEventId,
  setSelectedSpecialEventId,
  selectedMessage,
  setResultMessage,
  setOpenResults,
  setIsDialogLoading,
  isDialogLoading,
  setWarningMessage,
  setOpenWarning,
}) => {
  useEffect(() => {
    if (openConnectSpecialEventToMessageDialog) {
    }
  }, [openConnectSpecialEventToMessageDialog]);
  const deleteSpecialEventTargetsIFExists = async (targets: TargetEntity[]) => {
    var specialEventTargets = targets.filter((t) => t.name === "special-event");
    if (specialEventTargets?.length > 0) {
      var partKeys = specialEventTargets.map((t) => t.partitionKey ?? "");
      await TargetService.deleteTargets(partKeys ?? []).catch((e) =>
        console.log(e)
      );
    }
  };

  const handleConnectSpecialEventToMessage = async () => {
    setOpenResults(true);
    setIsDialogLoading(true);
    if (selectedSpecialEventId !== null) {
      TargetService.getTargets(selectedMessage?.partitionKey ?? "")
        .then((res) => deleteSpecialEventTargetsIFExists(res))
        .catch((e) => console.log(e));
      if (selectedSpecialEventId !== "-1") {
        await TargetService.postTargetsMultiple([
          {
            name: "special-event",
            value: selectedSpecialEventId,
            messageId: selectedMessage?.partitionKey,
          } as TargetEntity,
        ])
          .then(() => {
            handleCloseConnectSpecialEventToMessageDialog();
            setResultMessage(
              "A speciális esemény hozzárendelése sikeres volt."
            );
            setIsDialogLoading(false);
          })
          .catch((e) => {
            setOpenResults(false);
            setOpenWarning(true);
            setWarningMessage(
              "A speciális esemény hozzárendelése nem sikerült."
            );
            setIsDialogLoading(false);
          });
      } else {
        handleCloseConnectSpecialEventToMessageDialog();
        setResultMessage(
          "A speciális esemény hozzárendelésének eltávolítása sikeres volt."
        );
        setIsDialogLoading(false);
      }
    }
  };

  return (
    <DialogComponent
      isDialogLoading={isDialogLoading}
      setIsDialogLoading={setIsDialogLoading}
      openMessageDialog={openConnectSpecialEventToMessageDialog}
      onClose={handleCloseConnectSpecialEventToMessageDialog}
      title={"Speciális esemény kezelése"}
      handleClose={handleCloseConnectSpecialEventToMessageDialog}
      closeButtonText={"Mégse"}
      handleSave={handleConnectSpecialEventToMessage}
      saveButtonText={"Mentés"}
    >
      <DialogContent style={{ paddingTop: 0 }}>
        {specEvents?.length === 0 ? (
          <div>Nincsenek létrehozva sepciális események.</div>
        ) : (
          <StyledFormControl fullWidth margin="dense">
            <StyledInputLabel
              size="small"
              sx={{
                fontSize: 14,
                "&.Mui-focused": {
                  color: "rgb(0,119,255, 0.4)", // Set the desired label color when focused
                },
              }}
            >
              Speciális események
            </StyledInputLabel>
            <StyledSelect
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Speciális események"
              value={selectedSpecialEventId ?? "-1"}
              onChange={(event: any) => {
                setSelectedSpecialEventId(event?.target?.value ?? "");
              }}
            >
              <MenuItem
                dense
                disableGutters
                sx={{ pl: 1 }}
                key={-1}
                value={"-1"}
              >
                {"Nincs kiválasztva"}
              </MenuItem>
              {specEvents?.map((event, index) => (
                <MenuItem
                  dense
                  disableGutters
                  sx={{ pl: 1 }}
                  key={index}
                  value={event?.partitionKey ?? ""}
                >
                  {event?.name}
                </MenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        )}
      </DialogContent>
    </DialogComponent>
  );
};

export default MessageSpecialEventDialog;
