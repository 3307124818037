import { Tooltip } from "@mui/material";
import "./CircularMenu.css";
import { useContext } from "react";
import { RefreshContext } from "../../utils/GlobalContexts";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";

interface Props {
  activeMessagesClicked: boolean;
  setActiveMessagesClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleActiveMessagesMenuItem: React.FC<Props> = ({
  activeMessagesClicked,
  setActiveMessagesClicked,
}) => {
  const { setRefresh } = useContext(RefreshContext);
  return (
    <li>
      {activeMessagesClicked ? (
        <div className="placeholder">
          <div className="upside">
            <a href="#" className="button">
              <Tooltip title={"Aktív üzenetek szűrésének kikapcsolása"}>
                <ToggleOnIcon
                  style={{
                    height: "45px",
                    width: "45px",
                    marginBottom: "-5px",
                    fill: "#0eeb02",
                    marginRight: "-20px",
                  }}
                  onClick={() => {
                    setActiveMessagesClicked(false);
                    setRefresh(true);
                  }}
                />
              </Tooltip>
            </a>
          </div>
        </div>
      ) : (
        <div className="placeholder">
          <div className="upside">
            <a href="#" className="button">
              <Tooltip title={"Szűrés aktív üzenetekre"}>
                <ToggleOffIcon
                  style={{
                    height: "45px",
                    width: "45px",
                    marginBottom: "-5px",
                    marginRight: "-15px",
                  }}
                  onClick={() => {
                    setActiveMessagesClicked(true);
                    setRefresh(true);
                  }}
                />
              </Tooltip>
            </a>
          </div>
        </div>
      )}
    </li>
  );
};

export default ToggleActiveMessagesMenuItem;
