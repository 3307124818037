import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect } from "react";
import { formatFileSize } from "../../utils/HelperFunctions";
import { StyledButton } from "../button/StyledButton";
import { StyledTextField } from "../textfield/StyledTextfield";

const FileInput = styled("input")({
  display: "none",
});

export type ChipData = {
  label: string;
  key: number;
  value: string;
  color: string;
  type: string | null;
  file: File | null;
};

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
interface ChipsArrayProps {
  chips: ChipData[];
  setChips: React.Dispatch<React.SetStateAction<ChipData[]>>;
}
const ChipsArray: React.FC<ChipsArrayProps> = ({ chips, setChips }) => {
  const [selectedChip, setSelectedChip] = React.useState<ChipData | null>(null);
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const [videoPreview, setVideoPreview] = React.useState<string | null>(null);

  React.useEffect(() => {
    setSelectedChip(null);
  }, []);

  useEffect(() => {
    if (chips?.length < 1) setSelectedChip(null);
  }, [chips]);

  const handleChipChange = (e: any) => {
    const { name, value } = e.target;
    const updatedChipData = chips.map((chip) =>
      chip.key === selectedChip?.key ? { ...chip, [name]: value } : chip
    );

    setChips(updatedChipData);
    setSelectedChip((prev) => ({ ...prev, [name]: value } as any));
  };

  const handleClick = (data: ChipData) => {
    setSelectedChip(data);

    if (
      data?.file !== null &&
      (data?.file as any)?.contentType &&
      (data?.file as any)?.fileContents
    )
      setImagePreview(
        `data:${(data?.file as any)?.contentType};base64,${
          (data?.file as any)?.fileContents
        }`
      );
    else if (data?.file !== null && data?.file !== undefined)
      setImagePreview(URL.createObjectURL(data?.file));
    else setImagePreview(null);

    // const keyValueArray: { [key: string]: string | File }[] = chips.map(
    //   (chipData) => ({
    //     [chipData.label]:
    //       chipData.file !== null ? chipData.file : chipData.value,
    //   })
    // );
  };

  const handleImageSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const updatedChipData = chips.map((chip) =>
        chip.key === selectedChip?.key ? { ...chip, file: file } : chip
      );

      setChips(updatedChipData);
      setSelectedChip((prev) => ({ ...prev, file: file } as any));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const deleteSelectedImage = () => {
    const updatedChipData = chips.map((chip) =>
      chip.key === selectedChip?.key ? { ...chip, file: null } : chip
    );

    setChips(updatedChipData);
    setSelectedChip((prev) => ({ ...prev, file: null } as any));
    setImagePreview(null);
  };

  const handleVideoSelect = (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      const updatedChipData = chips.map((chip) =>
        chip.key === selectedChip?.key ? { ...chip, file: file } : chip
      );

      setChips(updatedChipData);
      setSelectedChip((prev) => ({ ...prev, file: file } as any));
      setVideoPreview(URL.createObjectURL(file));
    }
  };

  const deleteSelectedVideo = () => {
    // Delete selected video logic here
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chips.map((data) => {
        let icon;

        return (
          <ListItem key={data.key}>
            <Chip
              icon={icon}
              label={data.label}
              color="primary"
              style={{
                backgroundColor: data.color,
                color: "black",
                border:
                  selectedChip !== null && selectedChip?.key === data.key
                    ? "1px solid black"
                    : "none",
              }}
              onClick={(e) => handleClick(data)}
            />
          </ListItem>
        );
      })}
      {selectedChip !== null && (
        <Grid
          container
          p={1}
          sx={{ overflowX: "hidden", overflowY: "auto", maxHeight: "35vh" }}
        >
          {selectedChip.type && selectedChip.type === "szöveg" && (
            <Grid item xs={12}>
              <StyledTextField
                label={`${selectedChip?.label} értéke`}
                name="value"
                value={selectedChip?.value ?? ""}
                onChange={handleChipChange}
                fullWidth
                margin="dense"
                size="small"
              />
            </Grid>
          )}
          {selectedChip.type && selectedChip.type === "kép" && (
            <div>
              <Typography sx={{ fontSize: 15 }} variant="body1">
                Kép feltöltése
              </Typography>
              <label htmlFor="image-upload">
                <FileInput
                  id="image-upload"
                  type="file"
                  onChange={handleImageSelect}
                  accept="image/png, image/gif, image/jpeg"
                />
                <Button
                  variant="contained"
                  component="span"
                  style={{
                    backgroundColor: "#DCDCDC",
                    color: "gray",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    border: "2px solid gray",
                    height: "30px",
                    boxShadow: "-5px 5px 2px 0px rgba(0, 0, 0, 0.53)",
                    WebkitBoxShadow: "-5px 5px 2px 0px rgba(0, 0, 0, 0.53)",
                    MozBoxShadow: "-5px 5px 2px 0px rgba(0, 0, 0, 0.53)",

                    margin: "5px",
                    marginBottom: "15px",
                  }}
                >
                  Kiválasztás
                </Button>
              </label>
              <StyledButton
                sx={{
                  marginLeft: 1,
                  backgroundColor: "rgba(250, 62, 62, 0.6)",
                }}
                size="small"
                variant="contained"
                onClick={deleteSelectedImage}
                disabled={!selectedChip?.file}
              >
                Törlés
              </StyledButton>
              {imagePreview && (
                <Grid
                  container
                  spacing={1}
                  p={0}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <img
                      src={imagePreview}
                      alt="Selected"
                      style={{
                        width: "180px",
                        height: "150px",
                        marginTop: "0.5rem",
                      }}
                    />
                  </Grid>
                  {/* <Grid
                    item
                    xs={5}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Tooltip
                      title={
                        selectedChip.file?.name ??
                        (selectedChip.file as any)?.fileDownloadName
                      }
                    >
                      <span style={{ fontSize: 13 }}>
                        {selectedChip.file?.name ??
                          (selectedChip.file as any)?.fileDownloadName}
                      </span>
                    </Tooltip>
                  </Grid> */}
                  <Grid item style={{ fontSize: 13, marginLeft: "auto" }}>
                    {formatFileSize(
                      selectedChip.file?.size ??
                        (selectedChip.file as any)?.fileContents?.length ??
                        0
                    )}
                  </Grid>
                </Grid>
              )}
            </div>
          )}
          {selectedChip.type && selectedChip.type === "video" && (
            <div>
              <Typography variant="body1">Video feltöltése</Typography>
              <label htmlFor="video-upload">
                <FileInput
                  id="video-upload"
                  type="file"
                  onChange={handleVideoSelect}
                  accept="video/*" // Accepts any video file format
                />
                <StyledButton size="small" variant="contained">
                  Kiválasztás
                </StyledButton>
              </label>
              <StyledButton
                sx={{ marginLeft: 1 }}
                size="small"
                variant="contained"
                onClick={deleteSelectedVideo}
                disabled={!selectedChip?.file}
              >
                Kiválasztás
              </StyledButton>
              {videoPreview && (
                <video
                  src={videoPreview}
                  style={{ width: "100%", marginTop: "1rem" }}
                  controls
                />
              )}
            </div>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default ChipsArray;
