import "./CircularMenu.css";

const PlaceholderMenuItem = () => {
  return (
    <li>
      <div className="placeholder">
        <div className="upside">
          {/* <a href="#" className="button"></a> */}
        </div>
      </div>
    </li>
  );
};

export default PlaceholderMenuItem;
