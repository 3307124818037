import {
  Box,
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";

import { Edit, Tag } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import {
  convertIdsToProgramNames,
  convertProgramNamesToIds,
  cutLongString,
  formatDate,
} from "../../utils/HelperFunctions";
import { TargetEntity, MessageDto, TemplateDto } from "../../InfohubService";
import GirlIcon from "@mui/icons-material/Girl";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";

import {
  handleEditMessageClick,
  handleDeleteMessageClick,
} from "../../utils/MessageActions";
import { handleTargetsEditClick } from "../../utils/TargetActions";
import LaunchIcon from "@mui/icons-material/Launch";
import ElderlyIcon from "@mui/icons-material/Elderly";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import { productIds } from "../../utils/auth/Constants";
import IconWithToopltip from "../icons/IconWithTooltip";
import { ICON_TYPE } from "../../utils/GlobalEnums";

interface TableBodyComponentProps {
  messages: MessageDto[];
  activeFilters: boolean;
  isLoading: boolean;
  setOpenMessageDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenResults: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>;
  setUnsavedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>;
  setSelectedMessageTargets: React.Dispatch<
    React.SetStateAction<TargetEntity[]>
  >;
  setSelectedMessageTargetsUpdated: React.Dispatch<
    React.SetStateAction<TargetEntity[]>
  >;
  setOpenTargets: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setResultMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>;
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  setTemplates: React.Dispatch<React.SetStateAction<TemplateDto[]>>;
  handleSpecialEventConnectClick: (message: MessageDto) => void;
}

const TableBodyComponent: React.FC<TableBodyComponentProps> = ({
  messages,
  activeFilters,
  isLoading,
  setSelectedMessage,
  setOpenMessageDialog,
  setOpenResults,
  setSelectedMessageTargets,
  setSelectedMessageTargetsUpdated,
  setOpenTargets,
  setResultMessage,
  setOpenConfirmDialog,
  handleSpecialEventConnectClick,
  setIsDialogLoading,
  setOpenWarning,
  setWarningMessage,
  setTemplates,
  setUnsavedMessage,
}) => {
  return (
    <TableBody>
      {isLoading ? (
        <TableRow
          style={{
            backgroundColor: "white",
          }}
        >
          <TableCell colSpan={10}>
            <Box justifyContent={"center"} display={"flex"} mt={5} mb={5}>
              <CircularProgress size={80} />
            </Box>
          </TableCell>
        </TableRow>
      ) : messages?.length === 0 ? (
        <TableRow
          style={{
            backgroundColor: "white",
          }}
        >
          <TableCell colSpan={10}>
            <Box justifyContent={"center"} display={"flex"} mt={5} mb={5}>
              {activeFilters
                ? "Nincsen a megadott keresési feltételnek megfelelő adat."
                : "Nincsenek elérhető adatok."}
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        messages.map((message, index) => (
          <TableRow
            key={index}
            style={{
              backgroundColor: message?.isActive
                ? "rgba(170, 202, 249, 0.8)"
                : "white",
            }}
          >
            <TableCell sx={{ fontWeight: message?.isActive ? 700 : 400 }}>
              {message?.title ?? "-"}
            </TableCell>
            <TableCell>{`${message?.programTypes?.join(", ")} ${
              message?.productIds?.length
                ? `(${convertProgramNamesToIds(
                    message?.productIds ?? [],
                    productIds
                  ).join(", ")})`
                : ""
            }`}</TableCell>
            <TableCell>{message?.template ?? "-"}</TableCell>
            <TableCell sx={{ width: "140px" }}>
              <Typography variant="body2" color="text.primary">
                {`${
                  message?.createDate &&
                  formatDate(new Date(message?.createDate))
                } / ${
                  message?.updateDate
                    ? formatDate(new Date(message?.updateDate))
                    : "-"
                }`}
              </Typography>
            </TableCell>

            {/* width is the icons' width multiplied by their number */}
            <TableCell style={{ width: `${37 * 5}px` }}>
              <IconWithToopltip
                icon={ICON_TYPE.EDIT}
                onClickEvent={() =>
                  handleEditMessageClick(
                    message.partitionKey,
                    setOpenMessageDialog,
                    setIsDialogLoading,
                    setWarningMessage,
                    setOpenWarning,
                    setTemplates,
                    setUnsavedMessage,
                    setSelectedMessage
                  )
                }
                tooltipText="Szerkesztés"
              />
              <IconWithToopltip
                icon={ICON_TYPE.TARGET}
                onClickEvent={() =>
                  handleTargetsEditClick(
                    message,
                    setSelectedMessage,
                    setOpenResults,
                    setOpenTargets,
                    setResultMessage,
                    setSelectedMessageTargets,
                    setSelectedMessageTargetsUpdated,
                    setWarningMessage,
                    setOpenWarning,
                    setIsDialogLoading
                  )
                }
                tooltipText="Szűrőfeltételek kezelése"
              />
              <IconWithToopltip
                icon={ICON_TYPE.DELETE}
                onClickEvent={() =>
                  handleDeleteMessageClick(
                    message,
                    setSelectedMessage,
                    setOpenConfirmDialog,
                    setIsDialogLoading
                  )
                }
                tooltipText="Üzenet törlése"
              />

              <IconWithToopltip
                icon={ICON_TYPE.SPECIALEVENT}
                onClickEvent={() => handleSpecialEventConnectClick(message)}
                tooltipText="Speciális esemény kezelése"
              />

              <IconWithToopltip
                icon={ICON_TYPE.EXTERNALLINK}
                onClickEvent={() =>
                  message?.cdnUri &&
                  window?.open(message?.cdnUri, "_blank")?.focus()
                }
                tooltipText="Template megnyitása új oldalon"
              />
            </TableCell>
          </TableRow>
        ))
      )}
    </TableBody>
  );
};

export default TableBodyComponent;
