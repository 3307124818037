import { IconButton } from "@mui/material";
import { SetStateAction } from "react";
import ClearIcon from "@mui/icons-material/Clear";

interface Props {
  condition: boolean;
  clearAction: () => void;
}

const SelectClearButton: React.FC<Props> = ({ condition, clearAction }) => {
  return (
    <IconButton
      sx={{
        backgroundColor: "white",
        height: 20,
        width: 20,
        marginRight: "20px",
        display: condition ? "" : "none",
      }}
      onClick={(e) => clearAction()}
    >
      <ClearIcon sx={{ height: 20, width: 20 }} />
    </IconButton>
  );
};

export default SelectClearButton;
