import { Tooltip } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { SetStateAction } from "react";

interface Props {
  setOpenTemplateDialog: React.Dispatch<SetStateAction<boolean>>;
}

const AddTemplateMenuItem: React.FC<Props> = ({ setOpenTemplateDialog }) => {
  return (
    <li>
      <div className="placeholder">
        <div className="upside">
          <a href="#" className="button">
            <Tooltip title={"Új template"}>
              <PostAddIcon
                style={{
                  height: "45px",
                  width: "45px",
                  marginBottom: "-5px",
                }}
                onClick={() => {
                  setOpenTemplateDialog(true);
                }}
              />
            </Tooltip>
          </a>
        </div>
      </div>
    </li>
  );
};

export default AddTemplateMenuItem;
