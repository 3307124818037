import { FilterDto } from "../InfohubService";
import { convertIdsToProgramNames } from "./HelperFunctions";
import { productIds, programTypes } from "./auth/Constants";
import { handleLogout } from "./auth/authActions";

export const handleCloseFilterDialog = (
  setOpenFilterDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setUnsavedFilterDtos: React.Dispatch<React.SetStateAction<FilterDto[]>>,
  setProductIdFilters: React.Dispatch<React.SetStateAction<string[]>>,
  setProgramTypeFilters: React.Dispatch<React.SetStateAction<string[]>>
) => {
  setUnsavedFilterDtos([] as FilterDto[]);
  setProgramTypeFilters([] as string[]);
  setProductIdFilters([] as string[]);
  setOpenFilterDialog(false);
};

export const handleSearchEnter = (
  e: any,
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>,
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>,
  unsavedSearchPhrase: string
) => {
  if (e.key === "Enter") {
    doSearch(setSearchPhrase, setRefresh, unsavedSearchPhrase);
  }
};

export const doSearch = (
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>,
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>,
  unsavedSearchPhrase: string
) => {
  setSearchPhrase(unsavedSearchPhrase);
  setRefresh(true);
};

export const deleteSearch = (
  setUnsavedSearchPhrase: React.Dispatch<React.SetStateAction<string>>,
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>,
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setUnsavedSearchPhrase("");
  setSearchPhrase("");
  setRefresh(true);
};

export const deleteFilters = (
  setFilterDtos: React.Dispatch<React.SetStateAction<FilterDto[]>>,
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setFilterDtos([] as FilterDto[]);
  setRefresh(true);
};

export const handleOpenFilterDialog = (
  setUnsavedFilterDtos: React.Dispatch<React.SetStateAction<FilterDto[]>>,
  filterDtos: FilterDto[],
  setOpenFilterDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setProgramTypeFilters: React.Dispatch<React.SetStateAction<string[]>>,
  setProductIdFilters: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const programTypeFilters = convertIdsToProgramNames(
    filterDtos
      .filter(
        (dto) =>
          dto.name === "programType" &&
          dto.value !== null &&
          dto.value !== undefined
      )
      .map((x) => x.value as string),
    programTypes
  );
  const productIdFilters = convertIdsToProgramNames(
    filterDtos
      .filter(
        (dto) =>
          dto.name === "pid" && dto.value !== null && dto.value !== undefined
      )
      .map((x) => x.value as string),
    productIds
  );
  setProgramTypeFilters(programTypeFilters);
  setProductIdFilters(productIdFilters);
  setUnsavedFilterDtos(
    filterDtos.filter((dto) => dto.name !== "programType" && dto.name !== "pid")
  );
  setOpenFilterDialog(true);
};

export const handleSearchChange = async (
  e: any,
  setUnsavedSearchPhrase: React.Dispatch<React.SetStateAction<string>>
) => {
  setUnsavedSearchPhrase(e.target.value);
};

export const handleLogoutClick = async () => {
  await handleLogout();
};
