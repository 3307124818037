import { Tooltip } from "@mui/material";
import { handleSpecialEventsClick } from "../../utils/SpecialEventActions";
import "./CircularMenu.css";
import { SpecialEventDto } from "../../InfohubService";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";

interface Props {
  openSpecialEventsDialog: boolean;
  setSpecEvents: React.Dispatch<React.SetStateAction<SpecialEventDto[] | null>>;
  setOpenSpecialEventsDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

const SpecialEventsMenuItem: React.FC<Props> = ({
  openSpecialEventsDialog,
  setSpecEvents,
  setOpenSpecialEventsDialog,
  setIsDialogLoading,
  setWarningMessage,
  setOpenWarning,
}) => {
  return (
    <li>
      <div className="placeholder">
        <div className="upside">
          <a href="#" className="button">
            <Tooltip title={"Speciális események"}>
              <NotificationImportantIcon
                style={{
                  height: "45px",
                  width: "45px",
                  marginBottom: "-5px",
                  marginRight: "0px",
                  transition: "fill 0.2s",
                }}
                className={openSpecialEventsDialog ? "noti-icon" : ""}
                onClick={() =>
                  handleSpecialEventsClick(
                    setSpecEvents,
                    setOpenSpecialEventsDialog,
                    setIsDialogLoading,
                    setWarningMessage,
                    setOpenWarning
                  )
                }
              />
            </Tooltip>
          </a>
        </div>
      </div>
    </li>
  );
};

export default SpecialEventsMenuItem;
