import React, { useEffect, useState } from "react";
import "./App.scss";
import Routes from "./Routes";
import { OpenAPI } from "./InfohubService";
import { RefreshContext, RefreshProps } from "./utils/GlobalContexts";
import { acquireToken } from "./utils/auth/authActions";
import { ThemeProvider, createTheme } from "@mui/material";
import PageFooter from "./components/footer/PageFooter";

const theme = createTheme({
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
});

function App() {
  OpenAPI.TOKEN = async () => (await acquireToken()) as any;
  OpenAPI.BASE = process.env.REACT_APP_INFO_HUB_API_URI as string;
  const [refresh, setRefresh] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <RefreshContext.Provider
          value={{ refresh, setRefresh } as RefreshProps}
        >
          <Routes />
        </RefreshContext.Provider>
        <PageFooter />
      </div>
    </ThemeProvider>
  );
}

export default App;
