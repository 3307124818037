/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SpecialEventDto } from '../models/SpecialEventDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SpecialEventService {

    /**
     * @param partitionKey 
     * @returns SpecialEventDto Success
     * @throws ApiError
     */
    public static getSpecialevent(
partitionKey: string,
): CancelablePromise<SpecialEventDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/specialevent/{partitionKey}',
            path: {
                'partitionKey': partitionKey,
            },
        });
    }

    /**
     * @param partitionKey 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteSpecialevent(
partitionKey: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/specialevent/{partitionKey}',
            path: {
                'partitionKey': partitionKey,
            },
        });
    }

    /**
     * @returns SpecialEventDto Success
     * @throws ApiError
     */
    public static getSpecialevent1(): CancelablePromise<Array<SpecialEventDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/specialevent',
        });
    }

    /**
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static putSpecialevent(
formData?: {
PartitionKey?: string;
Active?: boolean;
Name?: string;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/specialevent',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param name 
     * @returns any Success
     * @throws ApiError
     */
    public static postSpecialevent(
name?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/specialevent',
            query: {
                'name': name,
            },
        });
    }

}
