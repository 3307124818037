import {
  DialogContent,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DialogComponent from "./DialogComponent";
import { useEffect } from "react";

interface ResultDialogProps {
  openResults: boolean;
  handleCloseResults: () => void;
  resultMessage: string;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isDialogLoading: boolean;
}

const ResultDialog: React.FC<ResultDialogProps> = ({
  openResults,
  handleCloseResults,
  resultMessage,
  setIsDialogLoading,
  isDialogLoading,
}) => {
  return (
    <DialogComponent
      isDialogLoading={isDialogLoading}
      setIsDialogLoading={setIsDialogLoading}
      openMessageDialog={openResults}
      onClose={handleCloseResults}
      title={""}
      handleClose={handleCloseResults}
      closeButtonText={"OK"}
    >
      <DialogContent style={{ paddingTop: 0 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.primary">
            {resultMessage}
          </Typography>
        </div>
      </DialogContent>
    </DialogComponent>
  );
};

export default ResultDialog;
