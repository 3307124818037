import { TableCell, TableHead, TableRow, styled } from "@mui/material";

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#f2f5fa",
  fontWeight: "bold",
}));

const TableHeaders = () => (
  <TableHead>
    <TableRow>
      <TableHeaderCell sx={{ maxWidth: "30%" }}>Cím</TableHeaderCell>
      <TableHeaderCell>Programtípusok</TableHeaderCell>
      <TableHeaderCell>Sablon</TableHeaderCell>
      <TableHeaderCell sx={{ width: "140px" }}>
        Létrehozás / Módosítás dátuma
      </TableHeaderCell>
      <TableHeaderCell style={{ width: `${37 * 5}px` }}>
        Műveletek
      </TableHeaderCell>
    </TableRow>
  </TableHead>
);

export default TableHeaders;
