import {
  DialogContent,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DialogComponent from "./DialogComponent";
import { useEffect } from "react";

interface WarningDialogProps {
  openWarning: boolean;
  handleCloseWarning: () => void;
  warningMessage: string;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isDialogLoading: boolean;
}

const WarningDialog: React.FC<WarningDialogProps> = ({
  openWarning,
  handleCloseWarning,
  warningMessage,
  setIsDialogLoading,
  isDialogLoading,
}) => {
  return (
    <DialogComponent
      isDialogLoading={isDialogLoading}
      setIsDialogLoading={setIsDialogLoading}
      openMessageDialog={openWarning}
      onClose={handleCloseWarning}
      title={"Figyelmeztetés"}
      handleClose={handleCloseWarning}
      closeButtonText={"Vissza"}
    >
      <DialogContent style={{ padding: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.primary">
            {warningMessage}
          </Typography>
        </div>
      </DialogContent>
    </DialogComponent>
  );
};

export default WarningDialog;
