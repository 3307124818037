import "./PageTitle.css";

const PageTitle = () => {
  return (
    <header className="header-page">
      <hgroup>
        <h1>Info-Hub</h1>
        <h2>Beúszó Manager</h2>
      </hgroup>
    </header>
  );
};

export default PageTitle;
