import {
  DialogContent,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  ListItemText,
  styled,
  css,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import DialogComponent from "./DialogComponent";
import {
  DataObject,
  LinkedItem,
  MessageDto,
  MessageService,
  StringStringKeyValuePair,
  TemplateDto,
} from "../../InfohubService";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { RefreshContext } from "../../utils/GlobalContexts";
import ChipsArray, { ChipData } from "./ChipsArray";
import {
  convertProgramNamesToIds,
  generateRandomPastelColor,
} from "../../utils/HelperFunctions";
import { arrayBuffer } from "stream/consumers";
import {
  getExistingTemplateVariables,
  handleMessageClose,
  handleMessageEdit,
  handleSaveMessageChanges,
} from "../../utils/MessageActions";
import { productIds, programTypes } from "../../utils/auth/Constants";
import { StyledTextField } from "../textfield/StyledTextfield";
import { StyledSelect } from "../select/StyledSelect";
import ClearIcon from "@mui/icons-material/Clear";
import SelectClearButton from "../button/SelectClearButton";

const labelStyles = css`
  margin-bottom: 3px;
  font-size: 14px;
  line-height: 1;
  color: #c4c4c4;

  &.Mui-focused {
    margin: 0;
    font-size: 15px;
    color: rgb(0, 119, 255, 0.4);
  }
`;

const formControlStyles = css`
  width: 100%;
  margintop: 5px;

  & label.Mui-focused {
    color: rgb(0, 119, 255, 0.2); /* Set the desired label color when focused */
  }

  & .MuiInputBase-root {
    height: 30px;
    font-size: 14px;
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: rgb(0, 119, 255, 0.2);
    }
  }
`;

export const StyledFormControl = styled(FormControl)`
  ${formControlStyles}
`;

export const StyledInputLabel = styled(InputLabel)`
  ${labelStyles}
`;

interface MessageDialogProp {
  openMessageDialog: boolean;
  selectedMessage: MessageDto | null;
  setResultMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenResults: React.Dispatch<React.SetStateAction<boolean>>;
  setWarningMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenWarning: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenMessageDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isDialogLoading: boolean;
  setSelectedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>;
  templates: TemplateDto[];
  setUnsavedMessage: React.Dispatch<React.SetStateAction<MessageDto | null>>;
  unsavedMessage: MessageDto | null;
}

const MessageDialog: React.FC<MessageDialogProp> = ({
  openMessageDialog,
  selectedMessage,
  setResultMessage,
  setOpenResults,
  setSelectedMessage,
  templates,
  setOpenMessageDialog,
  setIsDialogLoading,
  isDialogLoading,
  setOpenWarning,
  setWarningMessage,
  setUnsavedMessage,
  unsavedMessage,
}) => {
  const { setRefresh } = useContext(RefreshContext);
  const [chips, setChips] = useState([] as ChipData[]);

  useEffect(() => {
    getExistingTemplateVariables(unsavedMessage, templates, setChips);
  }, [unsavedMessage?.template]);

  function clearMessageDialogSelect(property: string, value: any): void {
    setUnsavedMessage((prev) => ({
      ...prev,
      [property]: value,
    }));
  }

  return (
    <DialogComponent
      isDialogLoading={isDialogLoading}
      setIsDialogLoading={setIsDialogLoading}
      openMessageDialog={openMessageDialog}
      onClose={() =>
        handleMessageClose(
          setOpenMessageDialog,
          setSelectedMessage,
          setChips,
          setUnsavedMessage
        )
      }
      title={
        isDialogLoading
          ? ""
          : unsavedMessage?.partitionKey
          ? "Üzenet szerkesztése"
          : "Új üzenet hozzáadása"
      }
      handleClose={() =>
        handleMessageClose(
          setOpenMessageDialog,
          setSelectedMessage,
          setChips,
          setUnsavedMessage
        )
      }
      handleSave={() =>
        handleSaveMessageChanges(
          setOpenMessageDialog,
          setUnsavedMessage,
          selectedMessage,
          setResultMessage,
          setRefresh,
          setOpenResults,
          chips,
          setChips,
          setIsDialogLoading,
          setOpenWarning,
          setWarningMessage,
          setUnsavedMessage,
          unsavedMessage
        )
      }
    >
      <DialogContent style={{ paddingTop: 0 }}>
        <FormControlLabel
          componentsProps={{ typography: { fontSize: 14 } }}
          control={
            <Checkbox
              name="isActive"
              onChange={(event) => handleMessageEdit(event, setUnsavedMessage)}
              checked={unsavedMessage?.isActive ?? false}
              sx={{
                color: "#c4c4c4", // Set the desired color for the active checkbox
                "&.Mui-checked": {
                  color: "rgb(0,119,255, 0.4)", // Set the desired color for the active checkbox when checked
                },
              }}
            />
          }
          label="Aktív"
        />
        <FormControlLabel
          componentsProps={{ typography: { fontSize: 14 } }}
          control={
            <Checkbox
              name="isDynamic"
              onChange={(event) => handleMessageEdit(event, setUnsavedMessage)}
              checked={unsavedMessage?.isDynamic ?? false}
              sx={{
                color: "#c4c4c4", // Set the desired color for the active checkbox
                "&.Mui-checked": {
                  color: "rgb(0,119,255, 0.4)", // Set the desired color for the active checkbox when checked
                },
              }}
            />
          }
          label="Dinamikus"
        />

        <StyledTextField
          id="title-id"
          label="Cím"
          name="title"
          value={unsavedMessage?.title ?? ""}
          onChange={(event) => handleMessageEdit(event, setUnsavedMessage)}
          fullWidth
          margin="dense"
          size="small"
        />
        <StyledFormControl sx={{ width: `552px` }} margin="dense">
          <StyledInputLabel size="small">Programtípusok</StyledInputLabel>
          <StyledSelect
            multiple
            size="small"
            name="programTypes"
            label="Programtípusok"
            value={unsavedMessage?.programTypes ?? []}
            onChange={(event) => handleMessageEdit(event, setUnsavedMessage)}
            renderValue={(selected) => (selected as any).join(", ")}
            endAdornment={
              <SelectClearButton
                condition={
                  (unsavedMessage?.programTypes &&
                    unsavedMessage?.programTypes?.length > 0) ??
                  false
                }
                clearAction={() => clearMessageDialogSelect("programTypes", [])}
              />
            }
          >
            {programTypes?.map((programType) => (
              <MenuItem
                dense
                disableGutters
                sx={{ p: 0 }}
                key={programType.productName}
                value={programType.productName}
              >
                <Checkbox
                  checked={
                    (unsavedMessage?.programTypes &&
                      unsavedMessage?.programTypes?.indexOf(
                        programType.productName
                      ) > -1) ||
                    false
                  }
                />
                <ListItemText primary={programType.productName} />
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledFormControl>
        {unsavedMessage?.programTypes?.includes("Főkönyv") && (
          <StyledFormControl sx={{ width: `552px` }} margin="dense">
            <StyledInputLabel size="small">Termékkódok</StyledInputLabel>
            <StyledSelect
              multiple
              size="small"
              name="productIds"
              label="Termékkódok"
              value={unsavedMessage?.productIds ?? []}
              onChange={(event) => handleMessageEdit(event, setUnsavedMessage)}
              renderValue={(selected: any) => selected.join(", ")}
              endAdornment={
                <SelectClearButton
                  condition={
                    (unsavedMessage?.productIds &&
                      unsavedMessage?.productIds?.length > 0) ??
                    false
                  }
                  clearAction={() => clearMessageDialogSelect("productIds", [])}
                />
              }
            >
              {productIds?.map((productId) => (
                <MenuItem
                  dense
                  disableGutters
                  sx={{ p: 0 }}
                  key={productId.productName}
                  value={productId.productName}
                >
                  <Checkbox
                    checked={
                      (unsavedMessage?.productIds &&
                        unsavedMessage?.productIds?.indexOf(
                          productId.productName
                        ) > -1) ||
                      false
                    }
                  />
                  <ListItemText primary={productId.productName} />
                </MenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        )}
        <StyledFormControl fullWidth margin="dense">
          <StyledInputLabel size="small">Template</StyledInputLabel>
          <StyledSelect
            value={unsavedMessage?.template ?? "-1"}
            name="template"
            label="Template"
            onChange={(event) => handleMessageEdit(event, setUnsavedMessage)}
            size="small"
          >
            <MenuItem dense disableGutters sx={{ pl: 1 }} key={-1} value={"-1"}>
              {"Nincs kiválasztva"}
            </MenuItem>
            {templates.map((template, index) => (
              <MenuItem
                dense
                disableGutters
                sx={{ pl: 1 }}
                key={index}
                value={template.name ?? ""}
              >
                {template.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledFormControl>

        <ChipsArray chips={chips} setChips={setChips} />
      </DialogContent>
    </DialogComponent>
  );
};

export default MessageDialog;
