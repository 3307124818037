import styled from "@emotion/styled";
import { Box, Tooltip } from "@mui/material";
import { ICON_TYPE } from "../../utils/GlobalEnums";
import LaunchIcon from "@mui/icons-material/Launch";
import { Edit, Tag } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";

interface Props {
  tooltipText: string;
  icon: ICON_TYPE;
  onClickEvent: () => void;
}

const IconWithToopltip: React.FC<Props> = ({
  tooltipText,
  icon,
  onClickEvent,
}) => {
  const LinkIconWrapper = styled(Box)(({ theme }) => ({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    backgroundColor: getIconColor(),
    cursor: "pointer",
    marginRight: 5,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "all 0.1s",
      transformOrigin: "50% 50%",
    },
  }));

  const getIcon = () => {
    switch (icon) {
      case ICON_TYPE.DELETE:
        return <DeleteIcon sx={{ color: "white" }} />;
      case ICON_TYPE.EDIT:
        return <Edit sx={{ color: "white" }} />;
      case ICON_TYPE.EXTERNALLINK:
        return <LaunchIcon sx={{ color: "white" }} />;
      case ICON_TYPE.SPECIALEVENT:
        return <NotificationImportantIcon sx={{ color: "white" }} />;
      case ICON_TYPE.TARGET:
        return <Tag sx={{ color: "gray" }} />;
      default:
        return null;
    }
  };

  const getIconColor = () => {
    switch (icon) {
      case ICON_TYPE.DELETE:
        return "#FF5B5B";
      case ICON_TYPE.EDIT:
        return "orange";
      case ICON_TYPE.EXTERNALLINK:
        return "#9457f7";
      case ICON_TYPE.SPECIALEVENT:
        return "#e38dd5";
      case ICON_TYPE.TARGET:
        return "#abd2ff";
      default:
        break;
    }
  };

  return (
    <Tooltip title={tooltipText}>
      <LinkIconWrapper onClick={onClickEvent}>{getIcon()}</LinkIconWrapper>
    </Tooltip>
  );
};

export default IconWithToopltip;
