/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageDto } from '../models/MessageDto';
import type { MessageListView } from '../models/MessageListView';
import type { MessagesParamDto } from '../models/MessagesParamDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessageService {

    /**
     * @param requestBody 
     * @returns MessageListView Success
     * @throws ApiError
     */
    public static postMessageFilter(
requestBody?: MessagesParamDto,
): CancelablePromise<MessageListView> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/message/filter',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param partitionKey 
     * @returns MessageDto Success
     * @throws ApiError
     */
    public static getMessage(
partitionKey: string,
): CancelablePromise<MessageDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/message/{partitionKey}',
            path: {
                'partitionKey': partitionKey,
            },
        });
    }

    /**
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static putMessage(
formData?: {
Template?: string;
PartitionKey?: string;
IsActive?: boolean;
IsDynamic?: boolean;
Files?: Array<Blob>;
FileInfos?: string;
DataJson?: string;
Title?: string;
ProgramTypes?: Array<string>;
ProductIds?: Array<string>;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/message',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param partitionKey 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteMessage(
partitionKey?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/message',
            query: {
                'partitionKey': partitionKey,
            },
        });
    }

    /**
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static postMessage(
formData?: {
Template?: string;
PartitionKey?: string;
IsActive?: boolean;
IsDynamic?: boolean;
Files?: Array<Blob>;
FileInfos?: string;
DataJson?: string;
Title?: string;
ProgramTypes?: Array<string>;
ProductIds?: Array<string>;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/message',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
