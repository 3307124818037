import React from "react";

export type RefreshProps = {
  refresh: boolean;
  setRefresh: any;
};

export const RefreshContext = React.createContext<RefreshProps>({
  refresh: false,
  setRefresh: null,
});
