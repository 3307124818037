import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

export const protectedResources = {
  infohubAPI: {
    scopes: {
      read: [
        (("api://" + process.env.REACT_APP_INFO_HUB_API_CLIENT_ID) as string) +
          "/InfoHub.AdminUser",
      ],
    },
  },
} as any;

export const loginRequest = {
  scopes: [...protectedResources.infohubAPI.scopes.read],
};

export const graphAPIRequest = {
  scopes: ["https://graph.microsoft.com/User.Read"],
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_INFO_HUB_UI_CLIENT_ID as string, // This is the ONLY mandatory field that you need to supply.
    authority:
      "https://login.microsoftonline.com/b1b8b4a0-3584-40fd-9b34-fda7fe81ffb9", // Replace the placeholder with your tenant subdomain
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
