/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TemplateDto } from '../models/TemplateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TemplateService {

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postTemplates(
requestBody?: TemplateDto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/templates',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns TemplateDto Success
     * @throws ApiError
     */
    public static getTemplates(): CancelablePromise<Array<TemplateDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/templates',
        });
    }

}
