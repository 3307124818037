import { Select, styled } from "@mui/material";

export const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#c4c4c4",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#cae2fc",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent",
  },
  "& .MuiSelect-select": {
    padding: 5,
    fontSize: 13,
    marginLeft: 10,
    color: "#2e2e2e",
  },
}));
