import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    height: 30,
    fontSize: 14,
    color: "#2e2e2e",
    "&.Mui-focused": {
      color: "black",
    },
  },
  "& label.Mui-focused": {
    color: "rgb(0,119,255, 0.4)",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "rgb(0,119,255, 0.4)",
    },
    "&:hover fieldset": {
      borderColor: "#cae2fc",
    },
  },
  "& label": {
    marginBottom: 3,
    fontSize: 14,
    lineHeight: 1,
    color: "#c4c4c4",
    "&.Mui-focused": {
      margin: 0,
      fontSize: 16,
    },
  },
}));
