import { Edit } from "@mui/icons-material";
import {
  DialogContent,
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button,
  styled,
  Box,
  CircularProgress,
} from "@mui/material";
import DialogComponent from "./DialogComponent";
import { SpecialEventDto, SpecialEventService } from "../../InfohubService";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledButton } from "../button/StyledButton";
import { StyledTextField } from "../textfield/StyledTextfield";

const DeleteIconWrapper = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderRadius: "50%",
  backgroundColor: "#FF5B5B",
  cursor: "pointer",
  marginRight: 5,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
}));

const EditIconWrapper = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderRadius: "50%",
  backgroundColor: "orange",
  cursor: "pointer",
  marginRight: 5,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
}));

interface SpecialEventsDialogProps {
  openSpecialEventsDialog: boolean;
  openEditSpecialEvent: (event: any) => void;
  specEvents: SpecialEventDto[] | null;
  setOpenConfirmDeleteSpecialEventDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setOpenSpecialEventsDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSpecialEventId: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  setSpecEvents: React.Dispatch<React.SetStateAction<SpecialEventDto[] | null>>;
  setIsDialogLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isDialogLoading: boolean;
}

const SpecialEventsDialog: React.FC<SpecialEventsDialogProps> = ({
  openSpecialEventsDialog,
  specEvents,
  openEditSpecialEvent,
  setSelectedSpecialEventId,
  setOpenConfirmDeleteSpecialEventDialog,
  setSpecEvents,
  setOpenSpecialEventsDialog,
  setIsDialogLoading,
  isDialogLoading,
}) => {
  const [showNewFieldSpecialEventDialog, setShowNewFieldSpecialEventDialog] =
    useState(false);
  const [newFieldSpecialEventDialog, setNewFieldSpecialEventDialog] = useState<
    string | null
  >(null);

  const handleCloseSpecialEventsDialog = () => {
    setShowNewFieldSpecialEventDialog(false);
    setNewFieldSpecialEventDialog(null);
    setOpenSpecialEventsDialog(false);
  };

  const cancelAddNewSpecialEventField = () => {
    setNewFieldSpecialEventDialog(null);
    setShowNewFieldSpecialEventDialog(false);
  };

  const handleNewFieldSpecialEventChange = (e: any) => {
    setNewFieldSpecialEventDialog(e?.target?.value);
  };

  const handleNewFieldSpecialEventEnter = (e: any) => {
    e.key === "Enter" && addNewSpecialEventField();
  };

  const addNewSpecialEventField = async () => {
    if (showNewFieldSpecialEventDialog) {
      newFieldSpecialEventDialog &&
        (await SpecialEventService.postSpecialevent(
          newFieldSpecialEventDialog
        ).catch((e) => console.log(e)));
      await SpecialEventService.getSpecialevent1()
        .then((res) => setSpecEvents(res))
        .catch((e) => console.log(e));
      setShowNewFieldSpecialEventDialog(false);
      setNewFieldSpecialEventDialog(null);
    } else {
      setShowNewFieldSpecialEventDialog(true);
    }
  };

  return (
    <DialogComponent
      isDialogLoading={isDialogLoading}
      setIsDialogLoading={setIsDialogLoading}
      openMessageDialog={openSpecialEventsDialog}
      onClose={handleCloseSpecialEventsDialog}
      title={"Speciális események"}
      handleClose={handleCloseSpecialEventsDialog}
      closeButtonText={"Bezár"}
    >
      {isDialogLoading ? (
        <DialogContent style={{ padding: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
            }}
          >
            <CircularProgress size={100} />{" "}
          </Box>
        </DialogContent>
      ) : (
        <DialogContent style={{ paddingTop: 0, maxHeight: "40vh" }}>
          <Grid container spacing={0}>
            <Grid item>
              <StyledButton onClick={addNewSpecialEventField}>
                {showNewFieldSpecialEventDialog ? "Hozzáadás" : "Új hozzáadása"}
              </StyledButton>
            </Grid>
            {showNewFieldSpecialEventDialog && (
              <Grid item>
                <StyledButton onClick={cancelAddNewSpecialEventField}>
                  Mégse
                </StyledButton>
              </Grid>
            )}
            {showNewFieldSpecialEventDialog && (
              <Grid item xs={true}>
                <StyledTextField
                  autoFocus={showNewFieldSpecialEventDialog}
                  label="Név"
                  name="name"
                  value={newFieldSpecialEventDialog ?? ""}
                  onChange={handleNewFieldSpecialEventChange}
                  onKeyDown={handleNewFieldSpecialEventEnter}
                  fullWidth
                  margin="dense"
                  size="small"
                />
              </Grid>
            )}
          </Grid>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Név</TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold" }}
                    width="75%"
                    align="right"
                  >
                    Státusz
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Műveletek</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {specEvents?.map((event) => (
                  <TableRow key={event?.partitionKey}>
                    <TableCell>{event?.name}</TableCell>
                    <TableCell width="75%" align="right">
                      {event?.active ? "Aktív" : "Inaktív"}
                    </TableCell>
                    <TableCell width="100%" style={{ minWidth: "80px" }}>
                      <Tooltip title="Szerkesztés">
                        <EditIconWrapper
                          onClick={() => openEditSpecialEvent(event)}
                        >
                          <Edit sx={{ color: "white" }} />
                        </EditIconWrapper>
                      </Tooltip>
                      <Tooltip title="Törlés">
                        <DeleteIconWrapper
                          onClick={() => {
                            setIsDialogLoading(false);
                            setOpenConfirmDeleteSpecialEventDialog(true);
                            setSelectedSpecialEventId(
                              event?.partitionKey ?? ""
                            );
                          }}
                        >
                          <DeleteIcon sx={{ color: "white" }} />
                        </DeleteIconWrapper>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      )}
    </DialogComponent>
  );
};

export default SpecialEventsDialog;
